<template>
  <div class="pb-10">
    <v-row>
      <v-col cols="12" md="9">
        <v-col class="stepBack pt-0">
          <v-btn
            class="pl-0 md:pl-4"
            @click.stop="backStep"
            text
            color="primary"
            :ripple="false"
            style="font-weight: 600"
          >
            <v-icon color="primary" class="mr-1">mdi-chevron-left</v-icon
            >{{ lang[getLanguage].BUT_BACK }}
          </v-btn>
        </v-col>
        <v-card flat mb-4>
          <span class="stepTitle">{{
            lang[getLanguage].TEST_DRIVE_PERSONAL_DATA
          }}</span>
          <v-row class="mt-6">
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title">{{
                lang[getLanguage].LABEL_FULL_NAME_KTP
              }}</span>
              <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                placeholder="contoh: John Doe"
                v-model="name"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title">NIK *</span>
              <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                placeholder="contoh: 3674000000"
                v-model="nik"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title">
                Alamat Email terdaftar pada aplikasi Gojek *
              </span>
              <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="emailGojek"
                placeholder="contoh: johndoe@gmail.com"
                :rules="emailRules"
                type="email"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title"> Alamat Email Pribadi * </span>
              <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                placeholder="contoh: johndoe@gmail.com"
                filled
                v-model="emailPribadi"
                :rules="emailRules"
                type="email"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0 relative">
              <span class="personal-input-title">
                No. Handphone terdaftar pada aplikasi Gojek *
              </span>
              <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="phoneNumberGojek"
                placeholder="contoh: +624718297372"
              >
              </v-text-field>
              <span
                class="customization-item-desc inline-block relative bottom-7"
              >
                <!-- {{ lang[getLanguage].TEST_DRIVE_CONTACT_DESCRIPTION }} -->
                Pastikan No. Handphone adalah nomor Aktif.
              </span>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title">
                No. Handphone Pribadi (yang terdaftar di WA) *
              </span>
              <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                placeholder="contoh: +624718297372"
                filled
                v-model="phoneNumberPribadi"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <!-- <v-layout
            style="width: 100%; margin-top: -1.5rem; margin-bottom: 2.5rem"
          >
            <span class="customization-item-desc">{{
              lang[getLanguage].TEST_DRIVE_CONTACT_DESCRIPTION
            }}</span>
          </v-layout> -->

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title"> Tempat Lahir * </span>
              <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="tempatLahir"
                placeholder="contoh: Jakarta"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title"> Tanggal Lahir * </span>
              <v-text-field
                class="custom-d-input custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                type="date"
                v-model="dateTglLahir"
                placeholder="johndoe@gmail.com"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <hr
            style="border-top: 1px dashed #c1c2c4; margin-top: 1rem"
            class="hidden md:block"
          />
          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full"
          ></span>

          <v-layout class="mt-6">
            <span class="stepTitle">{{
              lang[getLanguage].SUBSCRIBE_PARENT_TITLE
            }}</span>
          </v-layout>

          <v-layout class="mt-6" style="width: 100%">
            <v-row>
              <v-col cols="12" md="6">
                <span class="personal-input-title">{{
                  lang[getLanguage].SUBSCRIBE_MOTHER_LABEL
                }}</span>
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="lang[getLanguage].SUBSCRIBE_MOTHER_PLACEHOLDER"
                  v-model="motherName"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-layout>

          <hr
            style="border-top: 1px dashed #c1c2c4; margin-top: 1rem"
            class="hidden md:block"
          />
          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full"
          ></span>

          <v-layout class="mt-6">
            <span class="stepTitle">{{
              lang[getLanguage].SUBSCRIBE_PROFESSION_TITLE
            }}</span>
          </v-layout>
          <!-- <div v-if="selectedUser === 'driver'"> -->
          <v-layout class="mt-6" style="width: 100%">
            <v-row>
              <v-col cols="12" md="6">
                <span class="personal-input-title">
                  Nama Perusahaan Afiliasi *
                </span>
                <v-text-field
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  disabled
                  :placeholder="lang[getLanguage].SUBSCRIBE_COMPANY_PLACEHOLDER"
                  v-model="companyName"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <span class="personal-input-title"> ID Driver (Opsional) </span>
                <v-text-field
                  class="custom-d-input custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  placeholder="contoh: 540176557 "
                  v-model="idDriver"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-layout>

          <v-layout style="width: 100%">
            <v-row>
              <v-col cols="12" md="6">
                <span class="personal-input-title">
                  {{ lang[getLanguage].SUBSCRIBE_WORK_LENGTH_LABEL }}
                </span>
                <v-select
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  placeholder="contoh: 1-3 tahun"
                  v-model="yearOfExperience"
                  :items="
                    getLanguage === 'en'
                      ? yearOfExperienceListEnglish
                      : yearOfExperienceListIndo
                  "
                  item-text="title"
                  item-value="value"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <span class="personal-input-title">
                  {{ lang[getLanguage].SUBSCRIBE_INCOME_LABEL }}
                </span>
                <v-select
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="salary"
                  :placeholder="lang[getLanguage].SUBSCRIBE_INCOME_PLACEHOLDER"
                  :items="itemsSalary"
                  item-text="title"
                  item-value="value"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-layout>

          <hr
            style="border-top: 1px dashed #c1c2c4"
            class="hidden md:block md:mb-5"
          />
          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-4"
          ></span>

          <v-layout>
            <span class="stepTitle">{{
              lang[getLanguage].SUBSCRIBE_MARITAL_STATUS_TITLE
            }}</span>
          </v-layout>
          <v-layout class="mt-6">
            <span class="personal-input-title">{{
              lang[getLanguage].SUBSCRIBE_MARITAL_STATUS_LABEL
            }}</span>
          </v-layout>
          <v-layout
            class="selection mt-4"
            style="justify-content: space-between"
            v-if="getLanguage === 'id' && isMobileDevice"
          >
            <div class="selection" @click.stop="selectMaritalStatus('Single')">
              <v-icon
                color="#353638"
                style="padding-right: 0.5rem"
                v-if="maritalStatus !== 'Single'"
                >mdi-radiobox-blank
              </v-icon>
              <v-icon
                color="#6b4693"
                style="padding-right: 0.5rem"
                v-if="maritalStatus === 'Single'"
                >mdi-radiobox-marked
              </v-icon>
              <label class="personal-item-label">
                {{ lang[getLanguage].SUBSCRIBE_SINGLE_LABEL }}
                <!-- Belum Menikah -->
              </label>
            </div>
            <div class="selection" @click.stop="selectMaritalStatus('Married')">
              <v-icon
                color="#353638"
                style="padding-right: 0.5rem"
                v-if="maritalStatus !== 'Married'"
                >mdi-radiobox-blank
              </v-icon>
              <v-icon
                color="#6b4693"
                style="padding-right: 0.5rem"
                v-if="maritalStatus === 'Married'"
                >mdi-radiobox-marked
              </v-icon>
              <label class="personal-item-label">
                {{ lang[getLanguage].SUBSCRIBE_MARIED_LABEL }}
                <!-- Sudah Menikah -->
              </label>
            </div>
          </v-layout>
          <v-row
            class="selection mt-4"
            v-if="getLanguage === 'en' || !isMobileDevice"
          >
            <v-col>
              <div class="" @click.stop="selectMaritalStatus('Single')">
                <v-icon
                  color="#353638"
                  style="padding-right: 0.5rem"
                  v-if="maritalStatus !== 'Single'"
                  >mdi-radiobox-blank
                </v-icon>
                <v-icon
                  color="#6b4693"
                  style="padding-right: 0.5rem"
                  v-if="maritalStatus === 'Single'"
                  >mdi-radiobox-marked
                </v-icon>
                <label
                  style="
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #353638;
                  "
                >
                  {{ lang[getLanguage].SUBSCRIBE_SINGLE_LABEL }}
                  <!-- Belum Menikah -->
                </label>
              </div></v-col
            >
            <v-col
              ><div class="" @click.stop="selectMaritalStatus('Married')">
                <v-icon
                  color="#353638"
                  style="padding-right: 0.5rem"
                  v-if="maritalStatus !== 'Married'"
                  >mdi-radiobox-blank
                </v-icon>
                <v-icon
                  color="#6b4693"
                  style="padding-right: 0.5rem"
                  v-if="maritalStatus === 'Married'"
                  >mdi-radiobox-marked
                </v-icon>
                <label
                  style="
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #353638;
                  "
                >
                  {{ lang[getLanguage].SUBSCRIBE_MARIED_LABEL }}
                  <!-- Sudah Menikah -->
                </label>
              </div></v-col
            >
          </v-row>
          <div v-if="maritalStatus === 'Married'">
            <v-layout class="mt-6" style="width: 100%">
              <v-row>
                <v-col cols="12" md="6">
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_JOB_POSITION_LABEL
                  }}</span>
                  <v-select
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="
                      lang[getLanguage].SUBSCRIBE_JOB_POSITION_PLACEHOLDER
                    "
                    v-model="spousePositionLabel"
                    :items="
                      getLanguage === 'en'
                        ? spousePositionListEnglish
                        : spousePositionListIndo
                    "
                    item-text="title"
                    item-value="value"
                    @change="selectSpousePosition()"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_EMPLOYMENT_STATUS_LABEL
                  }}</span>
                  <v-select
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="
                      spousePositionLabel !== 'housewife'
                        ? lang[getLanguage]
                            .SUBSCRIBE_EMPLOYMENT_STATUS_PLACEHOLDER
                        : ''
                    "
                    v-model="spouseEmploymentStatus"
                    :items="
                      getLanguage === 'en'
                        ? employmentStatusListEnglish
                        : employmentStatusListIndo
                    "
                    item-text="title"
                    item-value="value"
                    :disabled="spousePositionLabel == 'housewife'"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-layout>
            <v-layout style="width: 100%">
              <v-row>
                <v-col cols="12" md="6">
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_COMPANY_NAME_LABEL
                  }}</span>
                  <v-text-field
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="
                      spousePositionLabel !== 'housewife'
                        ? lang[getLanguage]
                            .SUBSCRIBE_SPOUSE_COMPANY_NAME_PLACEHOLDER
                        : ''
                    "
                    v-model="spouseCompanyName"
                    :disabled="spousePositionLabel == 'housewife'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-layout>

            <v-layout style="width: 100%">
              <v-row>
                <v-col cols="12" md="6">
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_LABEL
                  }}</span>
                  <v-select
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="
                      spousePositionLabel !== 'housewife'
                        ? lang[getLanguage]
                            .SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_PLACEHOLDER
                        : ''
                    "
                    v-model="spouseYearOfExperience"
                    :items="
                      getLanguage === 'en'
                        ? spouseYearOfExperienceListEnglish
                        : spouseYearOfExperienceListIndo
                    "
                    item-text="title"
                    item-value="value"
                    :disabled="spousePositionLabel == 'housewife'"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-layout>

            <v-layout style="width: 100%">
              <v-row>
                <v-col cols="12" md="6">
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_SALARY_LABEL
                  }}</span>
                  <v-select
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    v-model="spouseSalary"
                    :placeholder="
                      spousePositionLabel !== 'housewife'
                        ? lang[getLanguage].SUBSCRIBE_SPOUSE_SALARY_PLACEHOLDER
                        : ''
                    "
                    :items="itemsSpouseSalary"
                    item-text="title"
                    item-value="value"
                    :disabled="spousePositionLabel == 'housewife'"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-layout>
          </div>

          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-3 mt-6"
          ></span>

          <!-- <v-layout pt-4>
            <v-spacer></v-spacer>
            <v-btn
              :style="
                isMobileDevice
                  ? 'width: 100%;color: #fff;text-transform: none !important;'
                  : 'width: 40%;color: #fff;text-transform: none !important;'
              "
              color="#6B4693"
              :disabled="isValid()"
              @click="stepTwoComplete"
              :loading="loadingBtnStep"
            >
              {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
            </v-btn>
          </v-layout> -->

          <span
            class="w-full border border-dashed md:inline-block hidden"
          ></span>
          <!-- Mobile -->
          <div class="flex justify-end mt-3 md:hidden">
            <v-btn
              class="capitalize font-work-sans font-medium text-base rounded-lg"
              color="primary"
              block
              :disabled="isValid()"
              :loading="loadingBtnStep"
              @click="stepTwoComplete"
            >
              {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <!-- Dekstop -->
          <div class="md:flex justify-end mt-3 hidden">
            <v-btn
              class="capitalize font-work-sans font-medium text-base rounded-lg"
              color="primary"
              right
              :disabled="isValid()"
              :loading="loadingBtnStep"
              @click="stepTwoComplete"
            >
              {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  getProfileApi,
  postPersonalInfoApi,
} from "../../../../services/back-end-ruby";
export default {
  name: "form-subs-step-2",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    windowHeight: Number,
    selectedBike: Object,
    index: Number,
    profile: Object,
    step: Number,
    stepTwoComplete: Function,
    backStep: Function,
  },
  data() {
    return {
      stepDone: false,
      menuTanggalLahir: false,
      loadingBtnStep: false,
      sendPersonalInfo: false,
      name: "",
      nik: "",
      emailGojek: "",
      emailPribadi: "",
      emailRules: [],
      phoneNumberGojek: "",
      phoneNumberPribadi: "",
      tempatLahir: "",
      dateTglLahir: "",
      address: "",
      motherName: "",
      companyName: "Mitra Driver Gojek",
      idDriver: "",
      employmentStatus: "",
      employmentStatusListEnglish: [
        {
          title: "Outsourced Employee/Partners",
          value: "outsorced",
        },
        {
          title: "Contract Employee",
          value: "contract",
        },
        {
          title: "Permanent Employee",
          value: "permanent",
        },
      ],
      employmentStatusListIndo: [
        {
          title: "Pegawai Outsourcing/Mitra Kerja",
          value: "outsorced",
        },
        {
          title: "Pegawai Kontrak",
          value: "contract",
        },
        {
          title: "Pegawai Tetap",
          value: "permanent",
        },
      ],
      jobPosition: "",
      jobPositionListEnglish: [
        {
          title: "Staff",
          value: "staff",
        },
        {
          title: "SPV",
          value: "spv",
        },
        {
          title: "Manager",
          value: "manager",
        },
        {
          title: "Director",
          value: "director",
        },
        {
          title: "Owner",
          value: "owner",
        },
      ],
      jobPositionListIndo: [
        {
          title: "Staff",
          value: "staff",
        },
        {
          title: "Supervisior",
          value: "spv",
        },
        {
          title: "Manajer",
          value: "manager",
        },
        {
          title: "Direktur",
          value: "director",
        },
        {
          title: "Pemilik",
          value: "owner",
        },
      ],
      yearOfExperience: "",
      yearOfExperienceListIndo: [
        {
          title: "< 1 tahun",
          value: "less_than_one_year",
        },
        {
          title: "1-3 tahun",
          value: "one_to_three_years",
        },
        {
          title: "4-6 tahun",
          value: "four_to_six_years",
        },
        {
          title: "> 6 tahun",
          value: "more_than_six_years",
        },
      ],
      yearOfExperienceListEnglish: [
        {
          title: "< 1 year",
          value: "less_than_one_year",
        },
        {
          title: "1-3 years",
          value: "one_to_three_years",
        },
        {
          title: "4-6 years",
          value: "four_to_six_years",
        },
        {
          title: "> 6 years",
          value: "more_than_six_years",
        },
      ],
      salary: "",
      // itemsSalary: [
      //   {
      //     title: "< Rp 2.500.000",
      //     value: "less_than_two_point_five_mill",
      //   },
      //   {
      //     title: "Rp 2.500.000 - Rp 5.000.000",
      //     value: "two_point_five_to_five_mill",
      //   },
      //   {
      //     title: "Rp 5.000.000 - Rp 7.500.000",
      //     value: "five_to_seven_point_five_mill",
      //   },
      //   {
      //     title: "Rp 7.500.000 - Rp 10.000.000",
      //     value: "seven_point_five_to_ten_mill",
      //   },
      //   {
      //     title: "> Rp 10.000.000",
      //     value: "more_than_ten_mill",
      //   },
      // ],
      itemsSalary: [
        {
          title: "< Rp 5.000.000",
          value: "less_than_five_mill",
        },
        {
          title: "Rp 5.000.000 - Rp 7.500.000",
          value: "five_to_seven_point_five_mill",
        },
        {
          title: "Rp 7.500.000 - Rp 13.000.000",
          value: "seven_point_five_to_thirteen_mill",
        },
        {
          title: "Rp 13.000.000 - Rp 20.000.000",
          value: "thirteen_mill_to_twenty_mill",
        },
        {
          title: "> Rp 20.000.000",
          value: "more_than_twenty_mill",
        },
      ],
      creditCardLimit: "",
      maritalStatus: "",
      maritalStatusList: [
        {
          en: "Maried",
          id: "Sudah Menikah",
        },
        {
          en: "Single",
          id: "Belum Menikah",
        },
      ],
      spouseCompanyName: "",
      spousePositionLabel: "",
      spouseEmploymentStatus: "",
      spousePositionListEnglish: [
        {
          title: "Housewife",
          value: "housewife",
        },
        {
          title: "Staff",
          value: "staff",
        },
        {
          title: "SPV",
          value: "spv",
        },
        {
          title: "Manager",
          value: "manager",
        },
        {
          title: "Director",
          value: "director",
        },
        {
          title: "Owner",
          value: "owner",
        },
      ],
      spousePositionListIndo: [
        {
          title: "Ibu Rumah Tangga",
          value: "housewife",
        },
        {
          title: "Staff",
          value: "staff",
        },
        {
          title: "Supervisior",
          value: "spv",
        },
        {
          title: "Manajer",
          value: "manager",
        },
        {
          title: "Direktur",
          value: "director",
        },
        {
          title: "Pemilik",
          value: "owner",
        },
      ],
      spouseYearOfExperience: "",
      spouseYearOfExperienceListIndo: [
        {
          title: "< 1 tahun",
          value: "less_than_one_year",
        },
        {
          title: "1-3 tahun",
          value: "one_to_three_years",
        },
        {
          title: "4-6 tahun",
          value: "four_to_six_years",
        },
        {
          title: "> 6 tahun",
          value: "more_than_six_years",
        },
      ],
      spouseYearOfExperienceListEnglish: [
        {
          title: "< 1 year",
          value: "less_than_one_year",
        },
        {
          title: "1-3 years",
          value: "one_to_three_years",
        },
        {
          title: "4-6 years",
          value: "four_to_six_years",
        },
        {
          title: "> 6 years",
          value: "more_than_six_years",
        },
      ],
      spouseSalary: "",
      itemsSpouseSalary: [
        {
          title: "< Rp 5.000.000",
          value: "less_than_five_mill",
        },
        {
          title: "Rp 5.000.000 - Rp 7.500.000",
          value: "five_to_seven_point_five_mill",
        },
        {
          title: "Rp 7.500.000 - Rp 13.000.000",
          value: "seven_point_five_to_thirteen_mill",
        },
        {
          title: "Rp 13.000.000 - Rp 20.000.000",
          value: "thirteen_mill_to_twenty_mill",
        },
        {
          title: "> Rp 20.000.000",
          value: "more_than_twenty_mill",
        },
      ],
      loadingData: false,
      loadingScreen: false,
      metaTitle: "Charged Asia - Ride for Good!",
      metaDesc:
        "Our mission at Charged is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.",
      metaImg: "",
      metaUrl: "",
      lang: this.$store.state.lang,
      loading: true,
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getServiceCity() {
      return this.$store.state.user.serviceCity;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    getDataGojekInformasiPribadi() {
      return this.$store.getters.getDataGojekInformasiPribadi;
    },
  },
  mounted() {},
  created() {
    this.$store.commit("setDataGojekStepDone", false);
    this.emailRules = [
      (v) => !!v || this.lang[this.getLanguage].VALIDATION_EMAIL_REQ,
      (v) =>
        /.+@.+\..+/.test(v) ||
        this.lang[this.getLanguage].VALIDATION_EMAIL_VALID,
      (v) =>
        (v || "").indexOf(" ") < 0 ||
        this.lang[this.getLanguage].VALIDATION_EMAIL_VALID,
    ];
  },
  methods: {
    async getData() {
      this.name = this.getDataGojekInformasiPribadi.name;
      this.nik = this.getDataGojekInformasiPribadi.nik;
      this.emailGojek = this.getDataGojekInformasiPribadi.emailGojek;
      this.emailPribadi = this.getDataGojekInformasiPribadi.emailPribadi;
      this.phoneNumberGojek =
        this.getDataGojekInformasiPribadi.phoneNumberGojek;
      this.phoneNumberPribadi =
        this.getDataGojekInformasiPribadi.phoneNumberPribadi;
      this.tempatLahir = this.getDataGojekInformasiPribadi.tempatLahir;
      this.dateTglLahir = this.getDataGojekInformasiPribadi.dateTglLahir;
      this.motherName = this.getDataGojekInformasiPribadi.motherName;
      this.idDriver = this.getDataGojekInformasiPribadi.idDriver;
      this.yearOfExperience =
        this.getDataGojekInformasiPribadi.yearOfExperience;
      this.salary = this.getDataGojekInformasiPribadi.salary;
      let aplhabet = this.getDataGojekInformasiPribadi.maritalStatus;
      this.maritalStatus = aplhabet.charAt(0).toUpperCase() + aplhabet.slice(1);
      this.spousePositionLabel =
        this.getDataGojekInformasiPribadi.spousePositionLabel;
      this.spouseEmploymentStatus =
        this.getDataGojekInformasiPribadi.spouseEmploymentStatus;
      this.spouseCompanyName =
        this.getDataGojekInformasiPribadi.spouseCompanyName;
      this.spouseYearOfExperience =
        this.getDataGojekInformasiPribadi.spouseYearOfExperience;
      this.spouseSalary = this.getDataGojekInformasiPribadi.spouseSalary;
    },
    selectMaritalStatus(item) {
      this.maritalStatus = "";
      this.maritalStatus = item;
      if (item === "Married") {
        this.spousePositionLabel = "";
        this.spouseEmploymentStatus = "";
        this.spouseCompanyName = "";
        this.spouseYearOfExperience = "";
        this.spouseSalary = "";
      }
    },
    selectSpousePosition() {
      if (
        this.spousePositionLabel === "Housewife" ||
        this.spousePositionLabel === "Ibu Rumah Tangga"
      ) {
        this.spouseEmploymentStatus = "";
        this.spouseCompanyName = "";
        this.spouseYearOfExperience = "";
        this.spouseSalary = "";
      }
    },
    async submit() {
      this.loadingBtnStep = true;
      // console.log("SUBMIT_STEP_2");
      this.$store.commit("setDataGojekInformasiPribadi", {
        name: this.name,
        nik: this.nik,
        emailGojek: this.emailGojek,
        emailPribadi: this.emailPribadi,
        phoneNumberGojek: this.phoneNumberGojek,
        phoneNumberPribadi: this.phoneNumberPribadi,
        tempatLahir: this.tempatLahir,
        dateTglLahir: this.dateTglLahir,
        motherName: this.motherName,
        companyName: this.companyName,
        idDriver: this.idDriver,
        yearOfExperience: this.yearOfExperience,
        salary: this.salary,
        maritalStatus: this.maritalStatus,
        spouseCompanyName: this.spouseCompanyName,
        spousePositionLabel: this.spousePositionLabel,
        spouseEmploymentStatus: this.spouseEmploymentStatus,
        spouseYearOfExperience: this.spouseYearOfExperience,
        spouseSalary: this.spouseSalary,
      });

      let token = this.$cookies.get("token_api");
      let opsiSingle = {
        company_name: this.companyName,
        job_position: "staff",
        employment_status: "permanent",
        length_of_work: this.yearOfExperience,
        profit_income: this.salary,
        credit_card_limit: "",
        employment_type: "user",
        employee_id: this.idDriver,
      };

      let opsiMerried = {
        company_name: this.spouseCompanyName,
        job_position: this.spousePositionLabel,
        employment_status: this.spouseEmploymentStatus,
        length_of_work: this.spouseYearOfExperience,
        profit_income: this.spouseSalary,
        credit_card_limit: "",
        employment_type: "spouse",
      };

      let compireOpsi =
        this.maritalStatus == "Single"
          ? [opsiSingle]
          : [opsiSingle, opsiMerried];

      // console.log(token, compireOpsi);

      await postPersonalInfoApi(token, {
        subscription_id: this.$store.getters.getIdSubs,
        guardian_name: this.motherName,
        marital_status: this.maritalStatus,
        nik: this.nik,
        birth_place: this.tempatLahir,
        birth_date: this.formatDate(this.dateTglLahir),
        employment: compireOpsi,
        work_email: this.emailGojek,
        work_mobile_no: this.phoneNumberGojek,
      }).then((res) => {
        // console.log("RESULTS_PERSONAL", res);
        if (res.meta.code == 200) {
          // this.stepper = 3;
          // this.$router.push("/form-subscribe/3");
          this.sendPersonalInfo = true;
          // this.serviceProvince = this.getServiceCity[0].province;
          // this.serviceCity = this.getServiceCity[0].district;
          // this.serviceDistrict = this.getServiceCity[0].regency;
          // this.serviceSubDistrict = this.getServiceCity[0].village;
          // this.serviceVillage = this.getServiceCity[0].village;
          // this.serviceZip = this.getServiceCity[0].postcode;

          // this.$store.commit("setVehiclePrice", {
          //   token: token,
          //   subscription_id: this.$store.getters.getIdSubs,
          //   customer_type: this.getProfile.customer_type,
          // });

          getProfileApi(token).then((res) => {
            this.$store.commit("setProfile", res.data.user);
          });

          this.$store.commit("setVehiclePrice", {
            token,
            customer_type: "mitra_driver_gojek",
            subscription_id: this.$store.getters.getIdSubs,
            reset: true,
          });

          this.$store.commit("setDataGojekStepDone", true);
          this.loadingBtnStep = false;
        } else {
          this.$store.commit("setDataGojekStepDone", false);
        }
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    isValid() {
      return (
        this.loadingBtnStep ||
        this.name === "" ||
        this.emailGojek === "" ||
        this.phoneNumberGojek === "" ||
        this.motherName === "" ||
        this.yearOfExperience === "" ||
        this.salary === "" ||
        this.maritalStatus === "" ||
        (this.spousePositionLabel !== "housewife" &&
          this.maritalStatus === "Married" &&
          this.spouseCompanyName === "") ||
        (this.spousePositionLabel !== "housewife" &&
          this.maritalStatus === "Married" &&
          this.spouseEmploymentStatus === "") ||
        (this.spousePositionLabel !== "housewife" &&
          this.maritalStatus === "Married" &&
          this.spouseYearOfExperience === "") ||
        (this.spousePositionLabel !== "housewife" &&
          this.maritalStatus === "Married" &&
          this.spouseSalary === "")
      );
    },
  },
};
</script>