<template>
  <div class="pb-10">
    <v-row>
      <v-col cols="12" md="8">
        <v-col class="stepBack pt-0">
          <v-btn
            class="pl-0 md:pl-4"
            @click.stop="backStep"
            text
            color="primary"
            :ripple="false"
            ><v-icon color="primary" class="mr-1">mdi-chevron-left</v-icon
            >{{ lang[getLanguage].BUT_BACK }}
          </v-btn>
        </v-col>

        <span class="stepTitle">{{
          lang[getLanguage].SUBSCRIBE_PRIVATE_DOCUMENT_TTILE
        }}</span>

        <v-col col="12" md="10" class="text-title">
          <v-row>
            <span class="text-info">
              {{ lang[getLanguage].SUBSCRIBE_PRIVATE_DOCUMENT_DESCRIPTION }}
            </span>
          </v-row>
        </v-col>

        <div v-for="(item, index) in file" :key="index.label">
          <div v-if="file[`${index}`].user.includes('driver')" class="mt-5">
            <span
              class="hidden w-full border border-dashed md:inline-block"
            ></span>
            <span
              class="md:hidden inline-block h-2 bg-charged-grey-700 w-full"
            ></span>
            <v-layout class="mt-6" style="width: 100%">
              <v-layout column class="relative">
                <span
                  class="text-base text-charged-grey-400 font-normal font-work-sans"
                  >{{
                    getLanguage === "en"
                      ? file[`${index}`].label.en
                      : file[`${index}`].label.id
                  }}</span
                >
                <v-btn
                  class="w-56 capitalize rounded-lg relative z-10"
                  color="primary"
                  elevation="2"
                  outlined
                  v-if="file[`${index}`].file === null"
                  @click="fileInputClick(index)"
                  ><v-icon style="color: #6b4693" left> mdi-plus </v-icon
                  >{{
                    lang[getLanguage].SUBSCRIBE_PRIVATE_DOCUMENT_UPLOAD_LABEL
                  }}</v-btn
                >
                <v-row
                  v-if="file[`${index}`].file !== null"
                  class="relative z-10"
                >
                  <v-col>
                    <v-card
                      :width="file[index]['uploadLoading'] ? 450 : 250"
                      max-width="350"
                      elevation="0"
                      outlined
                      class="rounded-md p-4"
                    >
                      <v-row>
                        <v-col cols="2">
                          <v-progress-circular
                            v-if="file[index]['uploadLoading']"
                            :size="25"
                            indeterminate
                            color="purple"
                          ></v-progress-circular>
                          <v-icon
                            v-if="!file[index]['uploadLoading']"
                            class="mr-1"
                          >
                            mdi-image-outline
                          </v-icon>
                        </v-col>

                        <v-col cols="8">
                          <span
                            class="text-truncate truncate block"
                            :style="
                              isMobileDevice
                                ? 'max-width: 150px'
                                : 'max-width: 150px'
                            "
                          >
                            {{ file[`${index}`]["file"]["name"] }}
                          </span>
                          <v-progress-linear
                            class="rounded-lg"
                            v-if="file[index]['uploadLoading']"
                            :value="file[index]['progressUpload']"
                          ></v-progress-linear>
                        </v-col>

                        <v-col cols="2" class="text-end">
                          <v-icon
                            :disabled="file[index]['uploadLoading']"
                            color="primary"
                            @click="fileDelete(index)"
                          >
                            mdi-delete-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <v-file-input
                  ref="refFilePicked"
                  class="style-file-input"
                  :rules="file[index].rulesFile"
                  accept="image/*,.pdf"
                  :id="index"
                  @change="onFilePicked()"
                  v-model="file[`${index}`].file"
                ></v-file-input>
              </v-layout>
            </v-layout>
            <v-col
              col="12"
              md="10"
              class="text-title"
              v-if="file[`${index}`].file === null"
            >
              <v-row>
                <span
                  class="font-work-sans text-sm font-normal text-charged-grey-400"
                >
                  {{
                    lang[getLanguage]
                      .SUBSCRIBE_PRIVATE_DOCUMENT_ITEM__DESCRIPTION
                  }}
                </span>
              </v-row>
            </v-col>
            <v-col v-if="file[index].noted" cols="12" md="10" class="px-0">
              <span
                class="font-work-sans text-sm font-normal text-charged-grey-400"
              >
                {{ file[index].noted }}
              </span>
            </v-col>
          </div>
        </div>

        <span
          class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mt-6 mb-3"
        ></span>
        <span class="hidden w-full border border-dashed md:inline-block"></span>

        <!-- Mobile -->
        <div class="flex justify-end mt-3 md:hidden">
          <v-btn
            class="capitalize font-work-sans font-medium text-base rounded-lg"
            color="primary"
            block
            :disabled="!validationStepFour"
            @click="stepFourComplete"
          >
            {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <!-- Dekstop -->
        <div class="md:flex justify-end mt-3 hidden">
          <v-btn
            class="capitalize font-work-sans font-medium text-base rounded-lg"
            color="primary"
            right
            :disabled="!validationStepFour"
            @click="stepFourComplete"
          >
            {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  getPrivateDocApi,
  postPrivateDocApi,
} from "../../../../services/back-end-ruby";
export default {
  name: "form-subs-step-4",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    windowHeight: Number,
    index: Number,
    profile: Object,
    step: Number,
    stepFourComplete: Function,
    backStep: Function,
  },
  data() {
    return {
      lang: this.$store.state.lang,
      validationStepFour: false,
      loadingBtnStep: false,
      file: {
        // identityCard: null,
        // familyRegistration: null,
        // taxpayerIdentificationNumber: null,
        // drivingLicense: null,
        // dashboardDriver: null,
        // accountTransaction: null,
        // payslip: null,
        identityCard: {
          rulesFile: [
            (v) => {
              if (!v) {
                return "Required!";
              }
              if (v && v.size > 2000000) {
                return "size should be less than 2 MB!";
              } else {
                return true;
              }
            },
          ],
          user: ["driver", "regular"],
          label: { en: "KTP *", id: "KTP *" },
          file: null,
          uploadLoading: false,
          progressUpload: 0,
          isUploaded: false,
          key: "file_ktp",
        },
        familyRegistration: {
          rulesFile: [
            (v) => {
              if (!v) {
                return "Required!";
              }
              if (v && v.size > 2000000) {
                return "size should be less than 2 MB!";
              } else {
                return true;
              }
            },
          ],
          user: ["driver", "regular"],
          label: { en: "KK *", id: "KK *" },
          file: null,
          uploadLoading: false,
          progressUpload: 0,
          isUploaded: false,
          key: "file_kk",
        },
        taxpayerIdentificationNumber: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver", "regular"],
          label: { en: "NPWP (Optional)", id: "NPWP (Opsional)" },
          file: null,
          noted:
            "Note : Jika Anda belum bisa mengunggah NPWP, Anda bisa melampirkan NPWP pada saat sesi tanda tangan kontrak.",
          uploadLoading: false,
          progressUpload: 0,
          isUploaded: false,
          key: "file_npwp",
        },
        drivingLicense: {
          rulesFile: [
            (v) => {
              if (!v) {
                return "Required!";
              }
              if (v && v.size > 2000000) {
                return "size should be less than 2 MB!";
              } else {
                return true;
              }
            },
          ],
          user: ["driver", "regular"],
          label: { en: "SIM C *", id: "SIM C *" },
          file: null,
          uploadLoading: false,
          progressUpload: 0,
          isUploaded: false,
          key: "file_sim_c",
        },
        dashboardDriver: {
          rulesFile: [
            (v) => {
              if (!v) {
                return "Required!";
              }
              if (v && v.size > 2000000) {
                return "size should be less than 2 MB!";
              } else {
                return true;
              }
            },
          ],
          user: ["driver"],
          label: {
            en: "Capture of Driver Dashboard Home Page *",
            id: "Capture Halaman Awal Dashboard Driver *",
          },
          file: null,
          uploadLoading: false,
          progressUpload: 0,
          isUploaded: false,
          key: "file_capture_dashboard_driver",
        },
        dashboardDriverIncomeOneMonth: {
          rulesFile: [
            (v) => {
              if (!v) {
                return "Required!";
              }
              if (v && v.size > 2000000) {
                return "size should be less than 2 MB!";
              } else {
                return true;
              }
            },
          ],
          user: ["driver"],
          label: {
            en: "Capture Dashboard Penghasilan Mitra Selama 1 Bulan *",
            id: "Capture Dashboard Penghasilan Mitra Selama 1 Bulan *",
          },
          file: null,
          uploadLoading: false,
          progressUpload: 0,
          isUploaded: false,
          key: "file_capture_dashboard_income_driver",
        },
        domisili: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          user: ["driver"],
          label: {
            en: "Domicile Letter (Optional)",
            id: "Surat Domisili (Optional)",
          },
          file: null,
          noted:
            "Note: Jika Anda belum bisa mengunggah Surat Domisili, Anda bisa melampirkan Surat Domisili pada saat sesi tanda tangan kontrak.",
          uploadLoading: false,
          progressUpload: 0,
          isUploaded: false,
          key: "file_domisili",
        },
      },
      selectedUser: "driver",
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getServiceCity() {
      return this.$store.state.user.serviceCity;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    getListVehicle() {
      return this.$store.getters.getListVehicle;
    },
    getListBatteryVehicle() {
      return this.$store.getters.getListBatteryVehicle;
    },
  },
  mounted() {},
  created() {
    this.$store.commit("setDataGojekStepDone", false);
  },
  methods: {
    async getData() {
      await getPrivateDocApi(this.$cookies.get("token_api")).then((ress) => {
        let fileKtp = ress.data.user_documents.find(
          (n) => n.document_type == "KTP"
        )?.file?.url;
        if (fileKtp) {
          let imgKtpName = fileKtp.split("/");
          this.file.identityCard.file = new File(
            [fileKtp],
            imgKtpName[imgKtpName.length - 1]
          );

          this.file.identityCard.isUploaded = true;
        }

        let fileKK = ress.data.user_documents.find(
          (n) => n.document_type == "KK"
        )?.file?.url;
        if (fileKK) {
          let imgKKName = fileKK.split("/");
          this.file.familyRegistration.file = new File(
            [fileKK],
            imgKKName[imgKKName.length - 1]
          );

          this.file.familyRegistration.isUploaded = true;
        }

        let fileNpwp = ress.data.user_documents.find(
          (n) => n.document_type == "NPWP"
        )?.file?.url;
        if (fileNpwp) {
          let imgNpwpName = fileNpwp.split("/");
          this.file.taxpayerIdentificationNumber.file = new File(
            [fileNpwp],
            imgNpwpName[imgNpwpName.length - 1]
          );

          this.file.taxpayerIdentificationNumber.isUploaded = true;
        }

        let fileSimC = ress.data.user_documents.find(
          (n) => n.document_type == "SIM C"
        )?.file?.url;
        if (fileSimC) {
          let imgSimCName = fileSimC.split("/");
          this.file.drivingLicense.file = new File(
            [fileSimC],
            imgSimCName[imgSimCName.length - 1]
          );

          this.file.drivingLicense.isUploaded = true;
        }

        let fileDashboardPage = ress.data.user_documents.find(
          (n) => n.document_type == "Capture Dashboard Driver"
        )?.file?.url;
        if (fileDashboardPage) {
          let imgDashboardPageName = fileDashboardPage.split("/");
          this.file.dashboardDriver.file = new File(
            [fileDashboardPage],
            imgDashboardPageName[imgDashboardPageName.length - 1]
          );

          this.file.dashboardDriver.isUploaded = true;
        }

        let fileDashboardIncome = ress.data.user_documents.find(
          (n) => n.document_type == "Capture Dashboard Income Gojek"
        )?.file?.url;
        if (fileDashboardIncome) {
          let imgDashboardIncomeName = fileDashboardIncome.split("/");
          this.file.dashboardDriverIncomeOneMonth.file = new File(
            [fileDashboardIncome],
            imgDashboardIncomeName[imgDashboardIncomeName.length - 1]
          );

          this.file.dashboardDriverIncomeOneMonth.isUploaded = true;
        }

        let fileDomicili = ress.data.user_documents.find(
          (n) => n.document_type == "Proof of Address"
        )?.file?.url;
        if (fileDomicili) {
          let imgDomiciliName = fileDomicili.split("/");
          this.file.domisili.file = new File(
            [fileDomicili],
            imgDomiciliName[imgDomiciliName.length - 1]
          );

          this.file.domisili.isUploaded = true;
        }

        this.onFilePicked();
      });
    },
    fileInputClick(inputElementId) {
      const element = document.getElementById(inputElementId);
      return element.click();
    },
    fileDelete(document) {
      this.file[document].file = null;
      this.file[document].uploadLoading = false;
      this.file[document].isUploaded = false;
      this.file[document].progressUpload = 0;
      this.validationStepFourCompleted();
    },
    async onFilePicked() {
      let token = this.$cookies.get("token_api");
      this.$refs.refFilePicked.forEach(async (e) => {
        e.validate();
        if (
          e.valid &&
          this.file[e.id].file &&
          !this.file[e.id].uploadLoading &&
          !this.file[e.id].isUploaded
        ) {
          this.file[e.id].uploadLoading = true;
          this.file[e.id].progressUpload = 0;

          let timeLoadFile = setInterval(() => {
            let intervalValue = (this.file[e.id].progressUpload += 30);

            this.file[e.id].progressUpload >= 90 ||
            !this.file[e.id].uploadLoading
              ? clearInterval(timeLoadFile)
              : intervalValue;
          }, 500);

          await postPrivateDocApi(token, {
            [this.file[e.id].key]: this.file[e.id].file,
            subscription_id: this.$store.getters.getIdSubs,
          }).then((res) => {
            if (res.meta.code == 200) {
              this.file[e.id].uploadLoading = false;
              this.file[e.id].isUploaded = true;
              this.file[e.id].progressUpload = 100;
            }
          });

          this.validationStepFourCompleted();
        }
      });

      this.validationStepFourCompleted();
    },
    validationStepFourCompleted() {
      if (
        !this.file.identityCard.isUploaded ||
        !this.file.familyRegistration.isUploaded ||
        !this.file.drivingLicense.isUploaded ||
        !this.file.dashboardDriver.isUploaded ||
        !this.file.dashboardDriverIncomeOneMonth.isUploaded
      ) {
        this.validationStepFour = false;
      } else {
        this.validationStepFour = true;
      }
    },
    async submit() {
      if (this.validationStepFour) {
        this.$store.commit("setDataGojekStepDone", true);
      } else {
        this.$store.commit("setDataGojekStepDone", false);
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>