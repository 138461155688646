<template>
  <v-sheet tile>
    <section>
      <div class="banner py-5">
        <v-container class="px-8">
          <span class="banner-title" style="font-size: 20px">{{
            lang[getLanguage].ACC_APP_PRIVACY
          }}</span>
        </v-container>
      </div>
      <v-container class="px-8 py-8">
        <p
          style="
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: #262626;
          "
        >
          {{ lang[getLanguage].PRIVACY_POLICY_LABEL }}
        </p>
        <div
          style="
            font-family: 'Work Sans';
            color: #535456;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          "
        >
          <p class="my-6">
            {{ lang[getLanguage].LABEL_EFFECTIVE_AS_OF }} :
            {{ lang[getLanguage].DATE_PRIVACY_POLICY }}
          </p>
          <p>
            {{ lang[getLanguage].PRIVACY_PARAGRAPH_1 }}
          </p>
          <p>
            <span> {{ lang[getLanguage].PRIVACY_PARAGRAPH_2 }} </span><br />
            <span>{{ lang[getLanguage].PRIVACY_PARAGRAPH_2_LINE_1 }}</span>
            <br />
            <span>{{ lang[getLanguage].PRIVACY_PARAGRAPH_2_LINE_2 }}</span>
          </p>
          <p style="font-weight: bold">
            {{ lang[getLanguage].PRIVACY_PARAGRAPH_3 }}
          </p>
          <p>
            {{ lang[getLanguage].PRIVACY_PARAGRAPH_4 }}
          </p>
          <div class="mt-6">
            <div style="font-weight: bold">
              {{ lang[getLanguage].PRVIACY_1_TITLE }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRVIACY_1_PARAGRAPH_1 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRVIACY_1_PARAGRAPH_2 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRVIACY_1_PARAGRAPH_3 }}
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">
              {{ lang[getLanguage].PRVIACY_2_TITLE }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_2_PARAGRAPH_1 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_2_PARAGRAPH_2 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_2_PARAGRAPH_3 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_2_PARAGRAPH_4 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_2_PARAGRAPH_5 }}
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">
              {{ lang[getLanguage.PRIVACY_3_TITLE] }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_1 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_2 }}
            </div>
            <div>
              <ul>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_2_LINE_1 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_2_LINE_2 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_2_LINE_3 }}
                </li>
              </ul>
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_3 }}
            </div>
            <div>
              <ul>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_3_LINE_1 }}
                </li>
                <li>
                  {{ lang[getLanguage.PRIVACY_3_PARAGRAPH_3_LINE_2] }}
                </li>
              </ul>
            </div>
            <div class="pt-4">
              {{ lang[getLanguage.PRIVACY_3_PARAGRAPH_4] }}
            </div>
            <div>
              <ul>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_4_LINE_1 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_4_LINE_2 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_4_LINE_3 }}
                </li>
              </ul>
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_5 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_6 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_7 }}
            </div>
            <div>
              <ul>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_7_LINE_1 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_7_LINE_2 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_7_LINE_3 }}
                </li>
              </ul>
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_8 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_9 }}
            </div>
            <div>
              <ul>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_9_LINE_1 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_3_PARAGRAPH_9_LINE_2 }}
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">
              {{ lang[getLanguage].PRIVACY_4_TITLE }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_1 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_2 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_3 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_4 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_5 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_6 }}
            </div>
            <div>
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_7 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_8 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_9 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_10 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_11 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_12 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_13 }}
            </div>
            <div>
              <ul>
                <li>
                  {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_14_LINE_1 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_14_LINE_2 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_14_LINE_3 }}
                </li>
              </ul>
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_15 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_16 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_17 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_4_PARAGRAPH_18 }}
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">
              {{ lang[getLanguage].PRIVACY_5_TITLE }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_5_PARAGRAPH_1 }}
            </div>
            <div>
              <ul>
                <li>
                  {{ lang[getLanguage].PRIVACY_5_PARAGRAPH_1_LINE_1 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_5_PARAGRAPH_1_LINE_2 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_5_PARAGRAPH_1_LINE_3 }}
                </li>
              </ul>
            </div>
            <div class="pt-4 bold-underline">
              {{ lang[getLanguage].PRIVACY_5_PARAGRAPH_2 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_5_PARAGRAPH_3 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_5_PARAGRAPH_4 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_5_PARAGRAPH_5 }}
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">
              {{ lang[getLanguage].PRIVACY_6_TITLE }}
            </div>
            <div class="pt-4 bold-underline">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_1 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_2 }}
            </div>
            <div>
              <ul>
                <li>
                  {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_2_LINE_1 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_2_LINE_2 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_2_LINE_3 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_2_LINE_4 }}
                </li>
              </ul>
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_3 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_4 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_5 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_6 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_7 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_8 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_9 }}
            </div>
            <div class="pt-4 bold-underline">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_10 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_11 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_12 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_6_PARAGRAPH_13 }}
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">
              {{ lang[getLanguage].PRIVACY_7_TITLE }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_7_PARAGRAPH_1 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_7_PARAGRAPH_2 }}
            </div>
            <div class="pt-4">
              <ul>
                <li>
                  {{ lang[getLanguage].PRIVACY_7_PARAGRAPH_2_LINE_1 }}
                </li>
                <li>
                  {{ lang[getLanguage].PRIVACY_7_PARAGRAPH_2_LINE_2 }}
                </li>
              </ul>
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_7_PARAGRAPH_3 }}
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">
              {{ lang[getLanguage].PRIVACY_8_TITLE }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_8_PARAGRAPH_1 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_8_PARAGRAPH_2 }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_8_PARAGRAPH_3 }}
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">
              {{ lang[getLanguage].PRIVACY_9_TITLE }}
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_9_PARAGRAPH_1 }}
            </div>
            <div class="pt-4">
              <span class="bold-underline">
                {{ lang[getLanguage].PRIVACY_9_PARAGRAPH_2 }}</span
              ><br />
              <span
                >{{ lang[getLanguage].LABEL_EMAIL }}: contact@charged.asia
              </span>
            </div>
            <div class="pt-4">
              {{ lang[getLanguage].PRIVACY_9_PARAGRAPH_3 }}
            </div>
          </div>
        </div>
      </v-container>
    </section>

    <v-footer class="pa-0">
      <Footer
        id="footer"
        :isMobileDevice="isMobileDevice"
        :dark="dark"
        :drawer="drawer"
        :windowWidth="windowWidth"
      >
      </Footer>
    </v-footer>
  </v-sheet>
</template>

<script>
import store from "@/store/index";

import Footer from "./FooterPressLaunch";
export default {
  name: "legal",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    drawer: Boolean,
    slug: String,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getPRivacyPolicySVG() {
      return this.getLanguage === "en"
        ? "./img/legal/privacy-policy-minified.svg"
        : "./img/legal/privacy-policy-indo-minified.svg";
    },
    getPRivacyPolicySVGHP() {
      return this.getLanguage === "en"
        ? "./img/legal/privacy-policy-hp-minified.svg"
        : "./img/legal/privacy-policy-indo-hp-minified.svg";
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
      //meta
      metaTitle: "Charged Asia - Ride for Good!",
      metaDesc:
        "Our mission at Charged is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.",
      metaImg: "",
      metaUrl: "",
      //class
      about_title_class:
        "text-over-image-white text-h1 aboutTitle text-center font-weight-bold montserrat",
      about_title_class_mobile:
        "text-over-image-white text-h2 aboutTitle_mobile text-center font-weight-bold montserrat",
      selectedTab: "",
    };
  },
  watch: {},
  components: {
    Footer,
  },
  created() {
    this.changeShowNavigation();
    this.changeTab();
  },
  methods: {
    changeShowNavigation() {
      store.commit("HideNav");
    },
    changeLanguage(lang) {
      store.commit("SetLanguage", {
        language: (this.getLanguage = lang),
      });
    },
    changeTab() {
      switch (this.slug) {
        case "privacy":
          this.selectedTab = "privacy";
          break;
        case "terms":
          this.selectedTab = "terms";
          break;
        default:
          this.selectedTab = "privacy";
          break;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.v-responsive__sizer {
  transition: padding-bottom 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  flex: 1 0 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.text-over-image-white {
  color: #ffffff;
}

.montserrat {
  font-family: "Montserrat";
}

.aboutTitle {
  justify-content: space-evenly;
  margin-top: 20%;
}

.aboutTitle_mobile {
  justify-content: space-evenly;
  margin-top: 70%;
}

.theme--light.v-sheet--outlined {
  border: none;
}

/* .v-btn {
  justify-content: flex-start !important;
} */

.mapouter {
  position: relative;
  text-align: center;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
}

.v-tab,
.v-tab:hover {
  background: none !important;
}

.theme--dark.v-btn:hover::before {
  opacity: 1;
}

.banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 0;
  gap: 8px;

  /* Charged/Purple/600 */

  background: #f6f2fa;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.banner-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  color: #262626;
}

.bold-underline {
  text-decoration: underline;
  font-weight: bold;
}
</style>
