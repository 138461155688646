<template>
  <div>
    <v-layout column v-if="loading">
      <p class="mt-0"></p>
      <v-layout column>
        <v-spacer></v-spacer>
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          width="100%"
        ></v-skeleton-loader>
        <v-spacer></v-spacer>
      </v-layout>
    </v-layout>
    <v-row v-else>
      <!-- <v-row> -->
      <v-col cols="12" md="8">
        <!-- START SECTION COLOR SELECTION -->
        <v-card flat mb-4>
          <span class="stepTitle">{{
            getLanguage === "en"
              ? `${selectedBike?.name} ${lang[getLanguage].PRODUCT_STEP_1}`
              : `${lang[getLanguage].PRODUCT_STEP_1} ${selectedBike?.name}`
          }}</span>
          <p class="mt-8 md:mt-2"></p>
          <span class="sectionStepTitle">{{
            lang[getLanguage].PRODUCT_COLOR_TITLE
          }}</span>
          <div
            v-for="(item, index) in vehicleAccessories.listColor"
            :key="index"
          >
            <v-layout
              class="d-flex justify-content-center align-items-center align-content-center"
              v-if="item.bikes.find((t) => selectedBike.name === t)"
              @click.stop="selectColor(item)"
            >
              <v-icon
                v-if="item !== selectedVehicleAcc.selectedColor"
                class="radio-button"
                >mdi-radiobox-blank</v-icon
              >
              <v-icon v-else color="#6b4693" class="radio-button"
                >mdi-radiobox-marked</v-icon
              >
              <v-card
                width="100%"
                height="60"
                flat
                :class="
                  item === selectedVehicleAcc.selectedColor
                    ? 'radio-selection selected-selection'
                    : 'radio-selection'
                "
              >
                <v-layout style="margin-top: 17px; margin-left: 10px">
                  <v-icon class="radio-selection-color" :color="item.color"
                    >mdi-circle
                  </v-icon>

                  <span class="customization-item-title">
                    -
                    {{ getLanguage === "en" ? item.title.en : item.title.id }}
                  </span>

                  <v-spacer></v-spacer>
                  <span
                    v-if="isMobileDevice"
                    class="customization-item-price"
                    style="margin-right: 10px"
                    >+ Rp
                    {{
                      CUSTOMER_TYPE.driver.includes(selectedUser)
                        ? formatPrice(item.price) +
                          "/" +
                          lang[getLanguage].LABEL_DAY
                        : formatPrice(item.price) +
                          "/" +
                          lang[getLanguage].LABEL_MO
                    }}
                  </span>
                  <span
                    v-if="!isMobileDevice"
                    class="customization-item-price"
                    style="margin-right: 10px"
                    >+ Rp
                    {{
                      CUSTOMER_TYPE.driver.includes(selectedUser)
                        ? formatPrice(item.price) +
                          "/" +
                          lang[getLanguage].LABEL_DAY
                        : formatPrice(item.price) +
                          "/" +
                          lang[getLanguage].LABEL_MO
                    }}
                  </span>
                </v-layout>
              </v-card>
            </v-layout>
          </div>
          <!-- END SECTION COLOR SELECTION -->

          <!-- START SECTION BATTERY SELECTION-->
          <p class="mt-12 md:mt-2"></p>
          <span class="sectionStepTitle">
            <!-- {{ lang[getLanguage].PRODUCT_BATTERY_TITLE }} -->
            {{ getLanguage == "en" ? "Battery quantity" : "Jumlah Baterai" }}

            <span
              class="text-sm text-charged-grey-400 font-work-sans font-normal"
            >
              {{
                getLanguage == "en"
                  ? "(Price includes tax)"
                  : "(Harga termasuk PPN)"
              }}
            </span>
          </span>
          <div v-if="isMobileDevice">
            <div
              v-for="(item, index) in vehicleAccessories.listBattery"
              :key="index + '2'"
            >
              <v-layout class="selection" @click.stop="selectBattery(item)">
                <v-icon
                  v-if="item !== selectedVehicleAcc.selectedBattery"
                  class="radio-button"
                  >mdi-radiobox-blank</v-icon
                >
                <v-icon
                  v-if="item === selectedVehicleAcc.selectedBattery"
                  color="#6b4693"
                  class="radio-button"
                  >mdi-radiobox-marked</v-icon
                >
                <v-card
                  :class="
                    item === selectedVehicleAcc.selectedBattery
                      ? 'radio-selection selected-selection'
                      : 'radio-selection'
                  "
                  flat
                >
                  <v-layout
                    class="my-2 mx-2"
                    style="display: block; font-size: 12px"
                  >
                    <v-row>
                      <v-col>
                        <v-layout>
                          <span>
                            <span class="customization-item-title">{{
                              getLanguage === "en"
                                ? item.title.en
                                : item.title.id
                            }}</span>
                          </span>
                          <v-spacer></v-spacer>
                          <span class="text-right">
                            <span class="customization-item-price"
                              >+ Rp{{
                                CUSTOMER_TYPE.driver.includes(selectedUser)
                                  ? formatPrice(item.dailyPrice) +
                                    "/" +
                                    lang[getLanguage].LABEL_DAY
                                  : formatPrice(item.price) +
                                    "/" +
                                    lang[getLanguage].LABEL_MO
                              }}</span
                            >
                          </span>
                        </v-layout>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col
                        ><span class="customization-item-desc">
                          {{
                            getLanguage === "en" ? item.desc.en : item.desc.id
                          }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-card>
              </v-layout>
            </div>
          </div>
          <div v-if="!isMobileDevice">
            <div
              v-for="(item, index) in vehicleAccessories.listBattery"
              :key="index"
            >
              <v-layout class="selection" @click.stop="selectBattery(item)">
                <v-icon
                  v-if="item !== selectedVehicleAcc.selectedBattery"
                  class="radio-button"
                  >mdi-radiobox-blank</v-icon
                >
                <v-icon
                  v-if="item === selectedVehicleAcc.selectedBattery"
                  color="#6b4693"
                  class="radio-button"
                  >mdi-radiobox-marked</v-icon
                >
                <v-card
                  :class="
                    item === selectedVehicleAcc.selectedBattery
                      ? 'radio-selection selected-selection'
                      : 'radio-selection'
                  "
                  flat
                >
                  <v-layout style="margin-top: 17px; margin-left: 10px">
                    <v-col col="12" md="8" class="radio-selection-battery">
                      <v-row>
                        <span class="customization-item-title">{{
                          getLanguage === "en" ? item.title.en : item.title.id
                        }}</span>
                      </v-row>
                      <v-row>
                        <p class="customization-item-desc">
                          {{
                            getLanguage === "en" ? item.desc.en : item.desc.id
                          }}
                        </p>
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <span
                      class="customization-item-price"
                      style="margin-right: 10px"
                      >+ Rp{{
                        CUSTOMER_TYPE.driver.includes(selectedUser)
                          ? formatPrice(item.dailyPrice) +
                            "/" +
                            lang[getLanguage].LABEL_DAY
                          : formatPrice(item.price) +
                            "/" +
                            lang[getLanguage].LABEL_MO
                      }}</span
                    >
                  </v-layout>
                </v-card>
              </v-layout>
            </div>
          </div>
          <!-- END SECTION BATTERY SELECTION -->

          <!-- START SECTION CHARGER SELECTION -->
          <p class="mt-12 md:mt-2"></p>
          <span class="sectionStepTitle">
            <!-- {{ lang[getLanguage].PRODUCT_CHARGER_TITLE }} -->
            {{ getLanguage == "en" ? "Charger type" : "Tipe Charger" }}

            <span
              class="text-sm text-charged-grey-400 font-work-sans font-normal"
            >
              {{
                getLanguage == "en"
                  ? "(Price includes tax)"
                  : "(Harga termasuk PPN)"
              }}
            </span>
          </span>
          <div
            v-for="(item, index) in vehicleAccessories.listCharger"
            :key="index + '1'"
          >
            <v-layout class="selection" @click.stop="selectCharger(item)">
              <v-icon
                v-if="item !== selectedVehicleAcc.selectedCharger"
                class="radio-button"
                >mdi-radiobox-blank</v-icon
              >
              <v-icon
                v-if="item === selectedVehicleAcc.selectedCharger"
                color="#6b4693"
                class="radio-button"
                >mdi-radiobox-marked</v-icon
              >
              <v-card
                :class="
                  item === selectedVehicleAcc.selectedCharger
                    ? 'radio-selection selected-selection'
                    : 'radio-selection'
                "
                flat
                :height="isMobileDevice ? 90 : 65"
                style="display: block"
              >
                <div v-if="isMobileDevice">
                  <v-layout
                    class="my-2 mx-2"
                    style="display: block; font-size: 12px"
                  >
                    <v-row>
                      <v-col>
                        <v-layout>
                          <span>
                            <span class="customization-item-title">
                              {{ item.title }}
                            </span>
                          </span>
                          <v-spacer></v-spacer>
                          <span class="text-right">
                            <span class="customization-item-price"
                              >+ Rp{{
                                CUSTOMER_TYPE.driver.includes(selectedUser)
                                  ? formatPrice(item.dailyPrice) +
                                    "/" +
                                    lang[getLanguage].LABEL_DAY
                                  : formatPrice(item.price) +
                                    "/" +
                                    lang[getLanguage].LABEL_MO
                              }}</span
                            >
                          </span>
                        </v-layout>
                        <v-layout>
                          <span class="customization-item-desc">{{
                            getLanguage === "en" ? item.time.en : item.time.id
                          }}</span>
                        </v-layout>
                        <v-layout>
                          <span class="customization-item-desc">{{
                            getLanguage == "en"
                              ? item.description.en
                              : item.description.id
                          }}</span>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-layout>
                </div>
                <div v-if="!isMobileDevice">
                  <v-layout style="margin-top: 17px; margin-left: 10px">
                    <span class="customization-item-title">{{
                      item.title
                    }}</span
                    ><span class="customization-item-desc"
                      >&nbsp;
                      {{
                        getLanguage === "en" ? item.time.en : item.time.id
                      }}</span
                    >
                    <v-spacer></v-spacer>
                    <span
                      class="customization-item-price"
                      style="margin-right: 10px"
                      >+ Rp{{
                        CUSTOMER_TYPE.driver.includes(selectedUser)
                          ? formatPrice(item.dailyPrice) +
                            "/" +
                            lang[getLanguage].LABEL_DAY
                          : formatPrice(item.price) +
                            "/" +
                            lang[getLanguage].LABEL_MO
                      }}</span
                    >
                  </v-layout>
                  <div class="pl-2 pb-5 inline-block">
                    <span
                      class="font-work-sans text-sm font-normal text-charged-grey-400"
                    >
                      {{
                        getLanguage == "en"
                          ? item.description.en
                          : item.description.id
                      }}
                    </span>
                  </div>
                </div>
              </v-card>
            </v-layout>
          </div>
          <!-- END SECTION CHARGER SELECTION -->

          <!-- START SECTION REAR SELECTION -->
          <div class="mt-10" v-if="vehicleAccessories.listRear.length > 0">
            <p class="mt-2"></p>
            <span class="sectionStepTitle">
              {{
                getLanguage == "en"
                  ? "Additional Accessories"
                  : "Tambahan Aksesoris"
              }}

              <span
                class="text-sm text-charged-grey-400 font-work-sans font-normal"
              >
                {{
                  getLanguage == "en"
                    ? "(Price includes tax)"
                    : "(Harga termasuk PPN)"
                }}
              </span>
            </span>
            <div v-if="isMobileDevice">
              <div
                v-for="(item, index) in vehicleAccessories.listRear"
                :key="index"
              >
                <v-layout class="selection" @click.stop="selectRear(item)">
                  <v-icon
                    v-if="item !== selectedVehicleAcc.selectedRear"
                    class="radio-button"
                    >mdi-radiobox-blank</v-icon
                  >
                  <v-icon
                    v-if="item === selectedVehicleAcc.selectedRear"
                    color="#6b4693"
                    class="radio-button"
                    >mdi-radiobox-marked</v-icon
                  >
                  <v-card
                    :class="
                      item === selectedVehicleAcc.selectedRear
                        ? 'radio-selection selected-selection'
                        : 'radio-selection'
                    "
                    flat
                  >
                    <v-layout
                      class="my-2 mx-2"
                      style="display: block; font-size: 12px"
                    >
                      <v-row>
                        <v-col class="col-7">
                          <span class="customization-item-title">{{
                            getLanguage === "en" ? item.title.en : item.title.id
                          }}</span>
                          <p class="customization-item-desc">
                            {{ item.description }}
                          </p>
                        </v-col>
                        <v-col class="text-right">
                          <span class="customization-item-price"
                            >+ Rp{{
                              CUSTOMER_TYPE.driver.includes(selectedUser)
                                ? formatPrice(item.dailyPrice) +
                                  "/" +
                                  lang[getLanguage].LABEL_DAY
                                : formatPrice(item.price) +
                                  "/" +
                                  lang[getLanguage].LABEL_MO
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-card>
                </v-layout>
              </div>
            </div>

            <div v-if="!isMobileDevice">
              <div
                v-for="(item, index) in vehicleAccessories.listRear"
                :key="index"
              >
                <v-layout class="selection" @click.stop="selectRear(item)">
                  <v-icon
                    v-if="item !== selectedVehicleAcc.selectedRear"
                    class="radio-button"
                    >mdi-radiobox-blank</v-icon
                  >
                  <v-icon
                    v-if="item === selectedVehicleAcc.selectedRear"
                    color="#6b4693"
                    class="radio-button"
                    >mdi-radiobox-marked</v-icon
                  >
                  <v-card
                    :class="
                      item === selectedVehicleAcc.selectedRear
                        ? 'radio-selection selected-selection'
                        : 'radio-selection'
                    "
                    flat
                  >
                    <v-layout style="margin-top: 17px; margin-left: 10px">
                      <v-col col="12" md="8" class="radio-selection-battery">
                        <v-row>
                          <span class="customization-item-title">{{
                            getLanguage === "en" ? item.title.en : item.title.id
                          }}</span>
                        </v-row>
                        <v-row>
                          <p class="customization-item-desc">
                            {{ item.description }}
                          </p>
                        </v-row>
                      </v-col>
                      <v-spacer></v-spacer>
                      <span
                        class="customization-item-price"
                        style="margin-right: 10px"
                        >+ Rp{{
                          CUSTOMER_TYPE.driver.includes(selectedUser)
                            ? formatPrice(item.dailyPrice) +
                              "/" +
                              lang[getLanguage].LABEL_DAY
                            : formatPrice(item.price) +
                              "/" +
                              lang[getLanguage].LABEL_MO
                        }}</span
                      >
                    </v-layout>
                  </v-card>
                </v-layout>
              </div>
            </div>
          </div>
          <!-- END SECTION REAR SELECTION -->
        </v-card>

        <p class="mt-4"></p>
        <p class="mt-4"></p>

        <hr
          style="border-top: 1px dashed #c1c2c4; margin-top: 1rem"
          class="hidden md:block"
        />

        <!-- Mobile -->
        <div class="flex justify-end mt-3 md:hidden">
          <v-btn
            class="capitalize font-work-sans font-medium text-base rounded-lg"
            color="primary"
            block
            :disabled="!isValid()"
            :loading="loadingBtnStep"
            @click="handleSubmitStepOne"
          >
            Lanjutkan
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <!-- Dekstop -->
        <div class="md:flex justify-end mt-3 hidden">
          <v-btn
            class="capitalize font-work-sans font-medium text-base rounded-lg"
            color="primary"
            right
            :disabled="!isValid()"
            :loading="loadingBtnStep"
            @click="handleSubmitStepOne"
          >
            Lanjutkan
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- DIALOG LOADER -->
    <v-dialog v-model="isLoad" persistent width="300">
      <v-card color="white" class="p-3">
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            :size="50"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text class="text-center text-charged-grey-100">
          Please stand by
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { CUSTOMER_TYPE } from "../../../../store/form-subscribe/data/non-gojek/const";
import { formatPrice } from "../../../../store/form-subscribe/data/non-gojek/utils";
import { postCustomizationApi } from "../../../../services/back-end-ruby/Vehicle";
import { getCurrentPlantApi } from "../../../../services/back-end-ruby";

export default {
  name: "step-1",
  props: {
    isMobileDevice: Boolean,
    goToForm: Function,
    handleSubmitStepOne: Function,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getbikeSubs() {
      return this.$store.getters.getSessionUser.bikeSubs;
    },
    getListVehicle() {
      return this.$store.getters.getListVehicle;
    },
    getListBatteryVehicle() {
      return this.$store.getters.getListBatteryVehicle;
    },
    getListChargersVehicle() {
      return this.$store.getters.getListChargersVehicle;
    },
    getListAksesorisVehicle() {
      return this.$store.getters.getListAksesorisVehicle;
    },
    getListColorsVehicle() {
      return this.$store.getters.getListColorsVehicle;
    },
    updateContent() {
      return this.vehicleAccessories;
    },
    getVehicleId() {
      return this.$store.getters.getVehicleId;
    },
  },
  data() {
    return {
      formatPrice,
      CUSTOMER_TYPE,
      loading: false,
      isLoad: false,
      loadingScreen: false,
      loadingBtnStep: false,
      selectedBike: null,
      selectedVehicleAcc: {
        selectedBattery: null,
        selectedCharger: null,
        selectedRear: null,
        selectedColor: null,
      },
      vehicleAccessories: {
        listColor: [],
        listCharger: [],
        listBattery: [],
        listRear: [],
      },
      totalPrice: 0,
      currentUser: {},
      selectedUser: "",
      lang: this.$store.state.lang,
    };
  },
  async created() {
    this.loading = true;
    await this.initUser();
    await this.initVehicleAcc();
    await this.initSelectedBikeAcc();
    this.loading = false;
  },
  async beforeMount() {},
  // async mounted() {
  //   this.loading = true;
  //   try {
  //     await this.initUser();
  //     await this.initVehicleAcc();
  //     await this.initSelectedBikeAcc();
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     this.loading = false;
  //   }
  // },
  methods: {
    async submit() {
      this.loadingBtnStep = true;
      const token = this.$cookies.get("token_api");
      await postCustomizationApi(token, {
        vehicle_color: this.selectedVehicleAcc.selectedColor.id,
        charger: this.selectedVehicleAcc.selectedCharger.id,
        battery: this.selectedVehicleAcc.selectedBattery.id,
        rearbox: this.selectedVehicleAcc.selectedRear?.id || "",
        vehicle_id: this.selectedBike.vehicle_id,
        subscription: this.$store.getters.getIdSubs,
      }).then((res) => {
        if (res.meta.code === 200) {
          this.loadingBtnStep = false;
          this.$store.commit("setDataStepDone", true);
          this.$store.commit("setDataKostumisasi", {
            bike: this.selectedBike,
            qtyBattery: this.selectedVehicleAcc.selectedBattery,
            typeCharge: this.selectedVehicleAcc.selectedCharger,
            accessories: this.selectedVehicleAcc.selectedRear,
            color: this.selectedVehicleAcc.selectedColor,
          });
        } else {
          this.$store.commit("setDataStepDone", false);
        }
      });
    },
    isAccEmpty(acc) {
      if (
        acc.listBattery === undefined ||
        acc.listCharger === undefined ||
        acc.listColor === undefined
      )
        return false;
      return (
        acc.listBattery.length < 1 &&
        acc.listCharger.length < 1 &&
        acc.listColor.length < 1
      );
    },
    isValid() {
      return (
        this.selectedVehicleAcc.selectedBattery !== null &&
        this.selectedVehicleAcc.selectedCharger !== null &&
        this.selectedVehicleAcc.selectedColor !== null
      );
    },
    async initUser() {
      this.currentUser = await this.getUser;
      this.selectedUser = this.currentUser.customerType;
    },
    async initVehicleAcc() {
      const [selectedBike, listBattery, listCharger, listRear, listColor] =
        await axios.all([
          this.getbikeSubs,
          this.getListBatteryVehicle,
          this.getListChargersVehicle,
          this.getListAksesorisVehicle,
          this.getListColorsVehicle,
        ]);

      this.vehicleAccessories = {
        listBattery: listBattery,
        listCharger: listCharger,
        listColor: listColor,
        listRear: listRear,
      };

      // this.selectedBike = selectedBike;
      this.selectedBike = this.getVehicleId
        ? this.getListVehicle.find((v) => v.vehicle_id == this.getVehicleId)
        : selectedBike;
    },
    async initSelectedBikeAcc() {
      const token = this.$cookies.get("token_api");
      const subsId = this.$store.getters.getIdSubs;
      const existedData = subsId
        ? await getCurrentPlantApi(token, subsId).then(
            (res) => res.data.subscription
          )
        : { subscription_accessories: [] };
      if (existedData.subscription_accessories.length < 1) {
        await new Promise((resolve) => {
          setTimeout(() => resolve("Ready Load"), 500);
        });
        this.$nextTick(() => {
          this.selectedVehicleAcc = {
            selectedBattery: this.getListBatteryVehicle[0] ?? null,
            selectedCharger: this.getListChargersVehicle[0] ?? null,
            selectedColor: this.getListColorsVehicle[0] ?? null,
            selectedRear: this.getListAksesorisVehicle[0] ?? null,
          };
        });
      } else {
        const subscribedAcc = existedData.subscription_accessories;
        const subscribedCharger = subscribedAcc?.find(
          (val) => val.accessory_type === "Charger"
        );
        const subscribedBattery = subscribedAcc?.find(
          (val) => val.accessory_type === "Battery"
        );
        const subscribedRear = subscribedAcc?.find(
          (val) => val.accessory_type === "Rearbox"
        );

        setTimeout(() => {
          const battery = this.vehicleAccessories.listBattery.find(
            (val) =>
              val.title.en === subscribedBattery?.accessory_name ||
              val.title.id === subscribedBattery?.accessory_name
          );
          const charger = this.vehicleAccessories.listCharger.find(
            (val) => val.title === subscribedCharger?.accessory_name
          );
          const color = this.vehicleAccessories.listColor.find(
            (val) =>
              val.title.en === existedData.subscription_color.name ||
              val.title.id === existedData.subscription_color.name
          );
          const rear = this.vehicleAccessories.listRear.find(
            (val) =>
              val.title.en === subscribedRear?.accessory_name ||
              val.title.id === subscribedRear?.accessory_name
          );

          this.selectedVehicleAcc = {
            selectedBattery: battery,
            selectedCharger: charger,
            selectedColor: color,
            selectedRear: rear,
          };
        }, 1000);
      }
    },
    selectColor(item) {
      this.selectedVehicleAcc = {
        ...this.selectedVehicleAcc,
        selectedColor: item,
      };
    },
    selectCharger(item) {
      this.selectedVehicleAcc = {
        ...this.selectedVehicleAcc,
        selectedCharger: item,
      };
    },
    selectBattery(item) {
      this.selectedVehicleAcc = {
        ...this.selectedVehicleAcc,
        selectedBattery: item,
      };
    },
    selectRear(item) {
      this.selectedVehicleAcc = {
        ...this.selectedVehicleAcc,
        selectedRear: item,
      };
    },
  },
};
</script>

<style></style>