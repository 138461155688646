<template>
  <div>
    <!-- <v-dialog
      v-model="subscribeNowDialog"
      fullscreen
      transition="dialog-bottom-transition"
      persistent
    >
    </v-dialog> -->

    <v-toolbar :dark="dark" :color="dark ? 'grey darken-4' : '#FFF'">
      <!-- <v-btn @click="subscribeNowDialogClosed" icon style="margin-right: 20px">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn> -->
      <v-icon>mdi-lock-outline</v-icon>
      <!-- <span style="margin-right: 5px">Secure Checkout by </span> -->
      <!-- <v-img
          src="img/icons/xendit_logo.png"
          max-width="64"
          max-height="24"
        ></v-img> -->
      <v-spacer></v-spacer>
      <!-- v-if="!isMobileDevice" -->
      <v-img :src="logoSrcDark" max-width="144" max-height="32"></v-img>
      <v-spacer></v-spacer>
      <v-icon v-if="!isMobileDevice">mdi-phone</v-icon>
      <span v-if="!isMobileDevice"
        >{{ lang[getLanguage].LABEL_NEED_HELP }}?
        <a
          href="https://wa.me/6282221188028?text=Hi+Charged+Indonesia%2C+Saya+perlu+bantuan"
          target="_blank"
          >+62 8222 1188 028</a
        ></span
      >
    </v-toolbar>
    <v-card
      :dark="dark"
      :style="dark ? 'background-color:#2a2a2a' : 'background-color:#FFF'"
    >
      <LayoutStepEmbedded
        ref="subscribe"
        :index="bikesCarousel"
        :isMobileDevice="isMobileDevice"
        :selectedBike="bikes[bikesCarousel]"
      ></LayoutStepEmbedded>
    </v-card>

    <v-dialog v-model="alertAlreadySubs" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Anda sudah subscribe </v-card-title>
        <v-card-text
          >Harap tunggu hingga data Anda disetujui sebelum mengambil sepeda
          Anda. Kami akan segera menghubungi Anda.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="alertAlreadySubsClose">
            tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getProfileApi } from "../../services/back-end-ruby";
// import SubscribeNow from "./comp/Subscribe";
import LayoutStepEmbedded from "./comp/form-embedded-form/LayoutStepEmbedded.vue";
export default {
  name: "form-subscribe",
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    drawer: Boolean,
  },
  computed: {
    getSessionUser() {
      return this.$store.getters.getSessionUser;
    },
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      alertAlreadySubs: false,
      lang: this.$store.state.lang,
      loading: false,
      signupDialog: false,
      profileFormDialog: false,
      subscribeNowDialog: false,
      checkServiceAreaDialog: false,
      captureEmailDialog: false,
      bikesCarousel: 0,
      bikes: [],
      logoSrcDark: "",
      email: "",
      customerCity: "",
      province: "",
      provinceNotAvailable: false,
      city: "",
      cityNotAvailable: false,
      regency: "",
      customerArea: "",
      valid: false,
      dataProvided: false,
      resetEmaiSent: false,
      emailSaved: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(v) ||
          "Email address must be valid",
      ],
    };
  },
  created() {
    this.subscribeNowDialog = true;
    // console.log("GET_SESSION_USER", this.getSessionUser);
    this.getBikes();
    this.logoSrcDark = "./img/logo/charged_logo.png";
    if (!this.getSessionUser.isLoggedIn) {
      this.$router.push("/");
    } else {
      let token_api = this.$cookies.get("token_api");
      getProfileApi(token_api).then((res) => {
        // console.log("RES_PROFILE", res);
        if (res.meta.code == 200) {
          this.$store.commit("setProfile", res.data.user);
          this.$store.commit("setIdSubs", res.data.subscription_id);
          this.$store.commit("setVehicle", {
            token: token_api,
            subscription_id: res.data.subscription_id,
          });
          if (res.data.subscription_id == null) {
            // this.$router.push("/");
            this.alertAlreadySubs = true;
          }
          // console.log(
          //   "GET_LIST_PRICE",
          //   this.$store.getters.getListVehiclePrice
          // );
          // console.log("GET_ID_SUBS", this.$store.getters.getIdSubs);
          // console.log(
          //   "GET_COLOR_VEHICLE",
          //   this.$store.getters.getListColorsVehicle
          // );
          // console.log(
          //   "GET_CHARGER_VEHICLE",
          //   this.$store.getters.getListChargersVehicle
          // );
          this.subscribeNowDialog = true;
        } else {
          this.isLoggedIn2 = false;
        }
      });
    }
  },
  components: {
    // SubscribeNow,
    LayoutStepEmbedded,
  },
  methods: {
    subscribeNowDialogClosed() {
      this.$refs.subscribe.subscribeClosed();
      this.subscribeNowDialog = false;
      this.$router.push("/");
    },
    getBikes() {
      this.bikes = this.bikes = this.$store.getters.getListVehicle;
    },
    alertAlreadySubsClose() {
      this.$router.push("/");
      this.alertAlreadySubs = false;
    },
  },
};
</script>