<template>
  <v-sheet tile>
    <section>
      <div class="banner py-5">
        <v-container class="px-8">
          <span class="banner-title" style="font-size: 20px">{{
            lang[getLanguage].ACC_APP_TERMS_OF_USE
          }}</span>
        </v-container>
      </div>
      <v-container class="px-8 py-8">
        <p
          style="
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: #262626;
          "
        >
          {{ lang[getLanguage].TERM_OF_USE_LABEL }}
        </p>
        <div
          style="
            font-family: 'Work Sans';
            color: #535456;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          "
        >
          <p class="my-6">
            {{ lang[getLanguage].LABEL_EFFECTIVE_AS_OF }} :
            {{ lang[getLanguage].DATE_PRIVACY_POLICY }}
          </p>
          <div class="mt-6">
            <div style="font-weight: bold">1. INFORMATION ABOUT US</div>
            <div class="pt-4">
              1.1 Charged Indonesia is a web-application operated by Charged
              Tech Indonesia ("We", "Us"). We are a company registered in
              Indonesia under PT Charged Tech Indonesia and have our registered
              office at Jl. Kemang Utara VII No.21B, Bangka, Kec. Mampang Prpt.,
              Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12730,
              Indonesia.
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">2. TERMS OF WEBSITE USE</div>
            <div class="pt-4">
              2.1 This page (together with the documents referred to on it) sets
              out the terms of use on which you may make use of the Charged
              Indonesia website and the Charged Indonesia mobile application
              (together and separately, "our site"), whether as a guest or a
              registered user. Please read these terms of use carefully before
              you start to use the site. By using our site, you indicate that
              you accept these terms of use, which include the privacy policy
              that applies to our site (the "Privacy Policy") and that you agree
              to abide by them. If you do not agree to these terms of use,
              please refrain from using our site.
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">3. INTELLECTUAL PROPERTY RIGHTS</div>
            <div class="pt-4">
              3.1 We are the owner or the licensee of all intellectual property
              rights in our site, and in the material published on it. Those
              works are protected by copyright laws and treaties around the
              world. All such rights are reserved to us and our licensors. As a
              visitor to our site, you may download a single copy of the
              material for your own non-commercial, private viewing purposes
              only. No copying or distribution for any commercial or business
              use is permitted without our prior written consent.
            </div>
            <div class="pt-4">
              3.2 You must not modify the paper or digital copies of any
              materials you have printed off or downloaded in any way, and you
              must not use any illustrations, photographs, video or audio
              sequences, or any graphics separately from any accompanying text.
              In addition, you may not include a link to our site or display the
              contents of our site surrounded or framed or otherwise surrounded
              by material not originating from Us without our consent.
            </div>
            <div class="pt-4">
              3.3 Our status (and that of any identified contributors) as the
              authors of material on our site must always be acknowledged.
            </div>
            <div class="pt-4">
              3.4 If you print off, copy or download any part of our site in
              breach of these terms of use, your right to use our site will
              cease immediately and you must, at our option, return or destroy
              any copies of the materials you have made.
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">4. ACCEPTABLE USE</div>
            <div class="pt-4">
              <div>
                4.1 You may use our site only for lawful purposes. You may not
                use our site:
              </div>
              <div style="margin-left: 49px">
                <div style="text-indent: -29px">
                  4.1.1 In any way that breaches any applicable local, national,
                  or international law or regulation.
                </div>
                <div style="text-indent: -29px">
                  4.1.2 In any way that is unlawful or fraudulent, or has any
                  unlawful or fraudulent purpose or effect.
                </div>
                <div style="text-indent: -29px">
                  4.1.3 For the purpose of harming or attempting to harm minors
                  in any way.
                </div>
                <div style="text-indent: -29px">
                  4.1.4 To send, knowingly receive, upload, download, use or
                  re-use any material which does not comply with our content
                  standards below.
                </div>
                <div style="text-indent: -29px">
                  4.1.5 To transmit, or procure the sending of, any unsolicited
                  or unauthorized advertising or promotional material or any
                  other form of similar solicitation (spam).
                </div>
                <div style="text-indent: -29px">
                  4.1.6 To knowingly transmit any data, send or upload any
                  material that contains viruses, Trojan horses, worms,
                  time-bombs, keystroke loggers, spyware, adware or any other
                  harmful programs or similar computer code designed to
                  adversely affect the operation of any computer software or
                  hardware.
                </div>
              </div>
              <div>
                4.2 You also agree not to access without authority, interfere
                with, damage, or disrupt
              </div>
              <div style="margin-left: 49px">
                <div style="text-indent: -29px">
                  4.2.1 any part of our site;
                </div>
                <div style="text-indent: -29px">
                  4.2.2 any equipment or network on which our site is stored;
                </div>
                <div style="text-indent: -29px">
                  4.2.3 any software used in the provision of our site; or
                </div>
                <div style="text-indent: -29px">
                  4.2.4 any equipment or network or software owned or used by
                  any third party.
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <div style="font-weight: bold">5. INTELLECTUAL PROPERTY RIGHTS</div>
            <div class="pt-4">
              5.1 We are the owner or the licensee of all intellectual property
              rights in our site, and in the material published on it. Those
              works are protected by copyright laws and treaties around the
              world. All such rights are reserved to us and our licensors. As a
              visitor to our site, you may download a single copy of the
              material for your own non-commercial, private viewing purposes
              only. No copying or distribution for any commercial or business
              use is permitted without our prior written consent.
            </div>
            <div class="pt-4">
              5.2 You must not modify the paper or digital copies of any
              materials you have printed off or downloaded in any way, and you
              must not use any illustrations, photographs, video or audio
              sequences, or any graphics separately from any accompanying text.
              In addition, you may not include a link to our site or display the
              contents of our site surrounded or framed or otherwise surrounded
              by material not originating from Us without our consent.
            </div>
            <div class="pt-4">
              5.3 Our status (and that of any identified contributors) as the
              authors of material on our site must always be acknowledged.
            </div>
            <div class="pt-4">
              5.4 If you print off, copy or download any part of our site in
              breach of these terms of use, your right to use our site will
              cease immediately and you must, at our option, return or destroy
              any copies of the materials you have made.
            </div>
            <div class="pt-4">
              5.5 The use of any of our Message Features by a minor is subject
              to the consent of their parent or guardian. We advise parents who
              permit their children to use our Message Features that it is
              important that they communicate with their children about their
              safety online, as moderation is not foolproof. Minors who are
              using any Message Feature should be made aware of the potential
              risks to them.
            </div>
            <div class="pt-4">
              5.6 Where we do moderate a Message Feature, we will normally
              provide you with a means of contacting the moderator, should a
              concern or difficulty arise.
            </div>
            <div class="pt-4">
              5.7 You must use Message Features in a responsible manner, and are
              solely responsible for any content you transmit. You must not
              transmit any message ("Message") in connection with any Message
              Feature that:
            </div>
            <div style="margin-left: 49px">
              <div style="text-indent: -29px">
                5.7.1 imposes an unreasonable or disproportionately large load
                on our site’s infrastructure, or otherwise adversely affects,
                restricts or inhibits any other user from using and enjoying our
                site;
              </div>
              <div style="text-indent: -29px">
                5.7.2 is threatening, abusive, libelous, defamatory, obscene,
                vulgar, offensive, pornographic, profane, sexually explicit or
                indecent;
              </div>
              <div style="text-indent: -29px">5.7.3 promotes violence;</div>
              <div style="text-indent: -29px">
                5.7.4 promotes discrimination based on race, sex, religion,
                nationality, disability, sexual orientation or age;
              </div>
              <div style="text-indent: -29px">
                5.7.5 constitutes or encourages conduct that would constitute a
                criminal offence, give rise to civil liability or otherwise
                violate any local, state, national or international law;
              </div>
              <div style="text-indent: -29px">
                5.7.6 violates, plagiarises or infringes the rights of third
                parties including, without limitation, copyright, trademark,
                patent, rights of privacy or publicity or any other proprietary
                right;
              </div>
              <div style="text-indent: -29px">
                5.7.7 breaches any legal duty owed to a third party, such as a
                contractual duty or a duty of confidence;
              </div>
              <div style="text-indent: -29px">
                5.7.8 contains any information, software or other material of a
                commercial nature;
              </div>
              <div style="text-indent: -29px">
                5.7.9 contains advertising, promotions or commercial
                solicitations of any kind;
              </div>
              <div style="text-indent: -29px">
                5.7.10 will be likely to harass, upset, embarrass, alarm or
                annoy any other person;
              </div>
              <div style="text-indent: -29px">
                5.7.11 constitutes or contains false or misleading indications
                of origin or statements of fact;
              </div>
              <div style="text-indent: -29px">
                5.7.12 is used to impersonate any person, or to misrepresent
                your identity or affiliation with any person (including giving
                the impression that such Message emanates from Us);
              </div>
              <div style="text-indent: -29px">
                5.7.13 contains material irrelevant to the subject matter of the
                Message Feature; or
              </div>
              <div style="text-indent: -29px">
                5.7.14 contains any virus, Trojan horse, worm, time bomb,
                cancelbot or other similar harmful programming routines.
              </div>
            </div>
            <div class="pt-4">
              5.8 We will determine, in our discretion, whether there has been a
              breach of our acceptable use policy or content use policy through
              your use of our site. When a breach of this policy has occurred,
              we may take such action as we deem appropriate (including but not
              limited to editing or deleting any Message or User Name
              transmitted by you).
            </div>
            <div class="pt-4">
              5.9 Failure to comply with this acceptable use policy constitutes
              a material breach of our terms of website use upon which you are
              permitted to use our site, and may result in our taking all or any
              of the following actions:
            </div>
            <div style="margin-left: 49px">
              <div style="text-indent: -29px">
                5.9.1 Immediate, temporary or permanent withdrawal of your right
                to use our site.
              </div>
              <div style="text-indent: -29px">
                5.9.2 Immediate, temporary or permanent removal of any posting
                or material uploaded by you to our site.
              </div>
              <div style="text-indent: -29px">
                5.9.3 Issue of a warning to you.
              </div>
              <div style="text-indent: -29px">
                5.9.4 Legal proceedings against you for reimbursement of all
                costs on an indemnity basis (including, but not limited to,
                reasonable administrative and legal costs) resulting from the
                breach.
              </div>
              <div style="text-indent: -29px">
                5.9.5 Further legal action against you.
              </div>
              <div style="text-indent: -29px">
                5.9.6 Disclosure of such information to law enforcement
                authorities as we reasonably feel is necessary.
              </div>
              <div style="text-indent: -29px">
                5.9.7 We exclude liability for actions taken in response to
                breaches of this acceptable use policy. The responses described
                in this policy are not limited, and we may take any other action
                we reasonably deem appropriate.
              </div>
              <div style="text-indent: -29px">
                5.9.7 We exclude liability for actions taken in response to
                breaches of this acceptable use policy. The responses described
                in this policy are not limited, and we may take any other action
                we reasonably deem appropriate.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">6. REGISTERED USERS</div>
              <div class="pt-4">
                6.1 In order to participate in certain Message Features and to
                access certain features of our site, you may be asked to
                register by providing certain personal information such as, for
                example, your name, mobile phone number and email address (our
                Privacy Policy /en/legal/privacy-policy explains how such
                information may be collected and used including our policy on
                cookies). You will also be asked to select a display name
                ("Display Name") for identification purposes. You must not use
                any Display Name that violates any term of subsections
                (5.7.1)-(5.7.12) above, or any other operating term set forth by
                our site. The decision to register a Display Name and password
                is at our discretion and we may revoke your account and password
                at any time.
              </div>
              <div class="pt-4">
                6.2 You are responsible for maintaining the confidentiality of
                your password and your account information, and you are solely
                responsible for all activities that occur under your password or
                account and for any access to or use of our site by you or any
                person or entity using your password, whether or not such access
                or use has been authorized by you, and whether or not such
                person or entity is your employee or agent.
              </div>
              <div class="pt-4">
                6.3 You must immediately notify us of any unauthorized use of
                your password or account or any other breach of security.
              </div>
              <div class="pt-4">
                6.4 We will not be liable for any loss or damage whatsoever
                resulting from the disclosure of your password contrary to these
                Terms of Use. You may not use another person's account at any
                time without the express permission of the account holder.
              </div>
              <div class="pt-4">
                6.5 You retain ownership of any content you post through your
                account.
              </div>
              <div class="pt-4">
                6.6 We have the right to store any content you post on our site,
                and at our discretion to make available any such content on our
                site, in each case indefinitely. However, we are not obliged to
                make available or otherwise exploit any such content. We are not
                responsible for any loss, theft, rights infringement or damage
                of any kind to or associated with any such content and you take
                sole responsibility for any such content that you provide to us.
              </div>
              <div class="pt-4">
                6.7 By posting or publishing any content via any Message or
                Submission Features, you are granting to Us a perpetual,
                royalty-free, non-exclusive, and irrevocable right and license
                to reproduce, prepare derivative works based upon, distribute,
                perform or display such content, in whole or in part, in any
                form, media or technology known or hereafter developed.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">7. CHILDREN AND YOUNG PEOPLE</div>
              <div class="pt-4">
                7.1 Our site is primarily aimed at users who are over the age of
                18. There are however no barriers to those under 18 accessing
                our site or registering as users. Users under 18 should only use
                our site with the permission of a parent or guardian. The
                parents or guardians of anyone under 18 using our site should
                review these Terms of Use carefully.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">
                8. INFORMATION AND AVAILABILITY
              </div>
              <div class="pt-4">
                8.1 While We take every care to ensure that the information on
                our site is accurate and complete, some of it is supplied to Us
                by third parties and We are unable to check its accuracy or
                completeness. You are advised to verify the accuracy of any
                information before relying on it. Further, due to the inherent
                nature of the internet, errors, interruptions, and delays may
                occur in the service at any time. Accordingly, our site is
                provided "as is" without any warranties of any kind and We do
                not accept any liability arising from any inaccuracy or omission
                in the information or interruption in availability.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">9. LINKS FROM OUR SITE</div>
              <div class="pt-4">
                9.1 Where our site contains links to other sites and resources
                provided by third parties we have no control over the contents
                of those sites or resources, and accept no responsibility for
                them or for any loss or damage that may arise from your use of
                them.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">10. COMPETITIONS AND VOTES</div>
              <div class="pt-4">
                10.1 We (or selected third parties) may include votes,
                competitions, promotions or other offers on our site. Each such
                offer shall be subject to its own express terms and conditions.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">11. MOBILE TERMS OF USE</div>
              <div class="pt-4">
                11.1 When accessing Charged Indonesia via mobile, standard
                network charges apply.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">12. VARIATION</div>
              <div class="pt-4">
                12.1 We may revise these terms of use at any time by amending
                this page. You are expected to check this page from time to time
                to take notice of any changes we make, as they are binding on
                you. Some of the provisions contained in these terms of use may
                also be superseded by provisions or notices published elsewhere
                on our site.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">13. INDEMNITY</div>
              <div class="pt-4">
                You agree to indemnify us and our affiliates and our respective
                directors, officers, employees and agents, as well as their
                licensors and suppliers, from and against any and all claims,
                actions, suits, or proceedings, as well as any and all losses,
                liabilities, damages, costs and expenses (including reasonable
                legal fees) arising out of:
              </div>
              <div class="pt-4">
                13.1 any misrepresentation, act or omission made by you in
                connection with your use of our site;
              </div>
              <div class="pt-4">
                13.2 any non-compliance by you with these Terms; or
              </div>
              <div class="pt-4">
                13.3 claims brought by third parties arising from or related to
                your access or use of our site, including without limitation the
                Message Features or other information made available by you to
                our site.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">14. LIABILITY</div>
              <div class="pt-4">
                14.1 In respect of all aspects of our site other than our supply
                of products (and subject to the other provisions of this Section
                14), Charged Indonesia, any other party (whether or not involved
                in creating, producing, maintaining or delivering our site), and
                any of our group companies and the officers, directors,
                employees, shareholders or agents of any of them, exclude all
                liability and responsibility for any amount or kind of loss or
                damage that may result to you or a third party (including
                without limitation, any direct, indirect, punitive or
                consequential loss or damages, or any loss of income, profits,
                goodwill, data, contracts, use of money, or loss or damages
                arising from or connected in any way to business interruption,
                and whether in tort (including without limitation negligence),
                contract or otherwise) in connection with our site in any way or
                the Message Features or in connection with the use, inability to
                use or the results of use of our site, any websites linked to
                our site or the material on such websites, including but not
                limited to loss or damage due to viruses that may infect your
                computer equipment, software, data or other property on account
                of your access to, use of, or browsing our site or your
                downloading of any material from our site or any websites linked
                to our site.
              </div>
              <div class="pt-4">
                14.2 If you choose to post your location, your intended location
                or any other such personal information via the Message Features
                or by any other method on our site you accept that this is
                entirely at your own risk and we are not responsible for any
                loss or harm which occurs as a result of your sharing any
                information about your location or intended location.
              </div>
              <div class="pt-4">
                14.3 This Section does not in any way limit our liability:
              </div>
              <div style="margin-left: 49px">
                <div style="text-indent: -29px">
                  14.3.1 for death or personal injury caused by our negligence;
                </div>
                <div style="text-indent: -29px">
                  14.3.2 under section 2(3) of the Consumer Protection Act 1987;
                </div>
                <div style="text-indent: -29px">
                  14.3.3 for fraud or fraudulent misrepresentation; or
                </div>
                <div style="text-indent: -29px">
                  14.3.4 for any matter for which it would be illegal for us to
                  exclude, or attempt to exclude our liability.
                </div>
              </div>
              <div class="pt-4">
                14.4 Where you buy any product or service from a third party
                seller through our site, the seller's individual liability will
                be set out in the seller's terms and conditions.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">
                15. JURISDICTION AND APPLICABLE LAW
              </div>
              <div class="pt-4">
                15.1 The Indonesian courts will have exclusive jurisdiction over
                any claim arising from, or related to, a visit to our site.
              </div>
              <div class="pt-4">
                15.2 These terms of use and any dispute or claim arising out of
                or in connection with them or their subject matter or formation
                (including non-contractual disputes or claims) shall be governed
                by and construed in accordance with the law of Indonesia.
              </div>
            </div>
            <div class="mt-6">
              <div style="font-weight: bold">16. CONTACT US</div>
              <div class="pt-4">
                16.1 If you have any concerns about material that appears on our
                site, please contact Us at contact@charged.asia (place "re:
                terms of service" in the e-mail's subject line).
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </section>

    <v-footer class="pa-0">
      <Footer
        id="footer"
        :isMobileDevice="isMobileDevice"
        :dark="dark"
        :drawer="drawer"
        :windowWidth="windowWidth"
      >
      </Footer>
    </v-footer>
  </v-sheet>
</template>

<script>
import store from "@/store/index";

import Footer from "./FooterPressLaunch";
export default {
  name: "legal",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    drawer: Boolean,
    slug: String,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getPRivacyPolicySVG() {
      return this.getLanguage === "en"
        ? "./img/legal/privacy-policy-minified.svg"
        : "./img/legal/privacy-policy-indo-minified.svg";
    },
    getPRivacyPolicySVGHP() {
      return this.getLanguage === "en"
        ? "./img/legal/privacy-policy-hp-minified.svg"
        : "./img/legal/privacy-policy-indo-hp-minified.svg";
    },
  },
  data() {
    return {
      lang: this.$store.state.lang,
      //meta
      metaTitle: "Charged Asia - Ride for Good!",
      metaDesc:
        "Our mission at Charged is 10 million electric vehicles in 10 years. We plan to start with electric motorcycles and lead the mass adoption of sustainable mobility in our region.",
      metaImg: "",
      metaUrl: "",
      //class
      about_title_class:
        "text-over-image-white text-h1 aboutTitle text-center font-weight-bold montserrat",
      about_title_class_mobile:
        "text-over-image-white text-h2 aboutTitle_mobile text-center font-weight-bold montserrat",
      selectedTab: "",
    };
  },
  watch: {},
  components: {
    Footer,
  },
  created() {
    this.changeShowNavigation();
    this.changeTab();
  },
  methods: {
    changeShowNavigation() {
      store.commit("HideNav");
    },
    changeLanguage(lang) {
      store.commit("SetLanguage", {
        language: (this.getLanguage = lang),
      });
    },
    changeTab() {
      switch (this.slug) {
        case "privacy":
          this.selectedTab = "privacy";
          break;
        case "terms":
          this.selectedTab = "terms";
          break;
        default:
          this.selectedTab = "privacy";
          break;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.v-responsive__sizer {
  transition: padding-bottom 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  flex: 1 0 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.text-over-image-white {
  color: #ffffff;
}

.montserrat {
  font-family: "Montserrat";
}

.aboutTitle {
  justify-content: space-evenly;
  margin-top: 20%;
}

.aboutTitle_mobile {
  justify-content: space-evenly;
  margin-top: 70%;
}

.theme--light.v-sheet--outlined {
  border: none;
}

/* .v-btn {
  justify-content: flex-start !important;
} */

.mapouter {
  position: relative;
  text-align: center;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
}

.v-tab,
.v-tab:hover {
  background: none !important;
}

.theme--dark.v-btn:hover::before {
  opacity: 1;
}

.banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 0;
  gap: 8px;

  /* Charged/Purple/600 */

  background: #f6f2fa;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.banner-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  color: #262626;
}

.bold-underline {
  text-decoration: underline;
  font-weight: bold;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}
</style>
