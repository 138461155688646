<template>
  <div class="pb-10">
    <v-row>
      <v-col cols="12" md="8">
        <v-col class="stepBack pt-0">
          <v-btn
            class="pl-0 md:pl-4"
            @click.stop="backStep"
            text
            color="primary"
            :ripple="false"
            ><v-icon color="primary" class="mr-1">mdi-chevron-left</v-icon
            >{{ lang[getLanguage].BUT_BACK }}
          </v-btn>
        </v-col>

        <span class="stepTitle">{{ lang[getLanguage].PRODUCT_STEP_5 }}</span>

        <span class="stepTitleSmall mt-10 inline-block">
          {{ getLanguage == "en" ? "Bank Account *" : "Akun Bank *" }}
        </span>

        <v-row class="mt-3">
          <v-col cols="12" class="pb-0">
            <span class="text-charged-grey-200">
              {{
                getLanguage == "en" ? "Account Bank Name *" : "Nama Akun Bank *"
              }}
            </span>
            <v-select
              class="custom-combobox-d"
              style="width: 100%"
              outlined
              dense
              filled
              v-model="bankName"
              :items="bankNameItems"
              item-text="title"
              item-value="value"
              :placeholder="
                getLanguage == 'en' ? 'Choose Bank Name' : 'Pilih Nama Bank'
              "
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row v-if="bankName == 'Bank Lainnya'">
          <v-col class="py-0">
            <v-text-field
              class="custom-d-input custom-combobox-d"
              style="width: 100%"
              outlined
              dense
              filled
              v-model="bankNameOthers"
              :placeholder="
                getLanguage === 'en' ? 'ex: CIMB NIAGA' : 'contoh: CIMB NIAGA'
              "
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <span class="text-charged-grey-200">
              {{ getLanguage == "en" ? "Account Number *" : "Nomor Akun *" }}
            </span>
            <v-text-field
              class="custom-d-input custom-combobox-d"
              style="width: 100%"
              outlined
              dense
              filled
              v-model="accountBankNumber"
              :placeholder="
                getLanguage === 'en' ? 'ex: 7321456789' : 'contoh: 7321456789'
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <span class="text-charged-grey-200">
              {{
                getLanguage == "en"
                  ? "Account Holder's Name *"
                  : "Nama Pemilik Akun *"
              }}</span
            >
            <v-text-field
              class="custom-d-input custom-combobox-d"
              style="width: 100%"
              outlined
              dense
              filled
              v-model="accountHoldersName"
              :placeholder="
                getLanguage === 'en' ? 'ex: Jhon Doe' : 'contoh: Jhon Doe'
              "
            >
            </v-text-field>
          </v-col>
        </v-row>

        <hr
          style="border-top: 1px dashed #c1c2c4"
          class="hidden md:block md:mt-3"
        />
        <span
          class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
        ></span>

        <v-col col="12" md="10" class="text-title">
          <v-row>
            <span class="stepTitleSmall">{{
              lang[getLanguage].PRODUCT_PRICING_TITLE
            }}</span>
          </v-row>
          <v-row>
            <span
              class="text-info text-base text-charged-grey-400 font-work-sans"
              >{{ lang[getLanguage].PRODUCT_PRICING_DESCRIPTION }}</span
            >
          </v-row>
        </v-col>

        <!-- START LIST PLAN PRICE  -->
        <div class="p-3">
          <!-- disini -->
          <v-radio-group v-model="selectedPricing" mandatory>
            <v-row class="pricing md:ml-1 gap-x-3 md:flex-nowrap">
              <v-col
                cols="12"
                md="3"
                v-for="(item, index) in listPricing"
                :key="index"
                :class="
                  item.bikes.includes(selectedBike.name) ===
                    selectedPricing.bikes.includes(selectedBike.name) &&
                  item.plan.en === selectedPricing.plan.en
                    ? 'selected-selection p-0 mb-2'
                    : 'p-0 mb-2'
                "
                @click.stop="selectPricing(item)"
              >
                <v-card
                  v-if="item.bikes.find((t) => selectedBike.name === t)"
                  class="pricing-item"
                >
                  <div
                    class="pricing-item-title text-center text-xl"
                    style="min-width: 160px; letter-spacing: -0.6px"
                  >
                    {{ getLanguage === "en" ? item.plan.en : item.plan.id }}
                  </div>
                  <div class="pricing-item-allprice">
                    <span
                      class="font-work-sans pricing-item-price text-lg text-charged-grey-300"
                      style="letter-spacing: -0.36px"
                      >Rp{{
                        selectedUser === "driver"
                          ? formatPrice(item.dailyPrice) +
                            "/" +
                            lang[getLanguage].LABEL_DAY
                          : formatPrice(item.price) +
                            "/" +
                            lang[getLanguage].LABEL_MO
                      }}</span
                    >
                    <span
                      class="font-work-sans pricing-item-billed text-charged-grey-400"
                    >
                      {{
                        selectedUser === "regular"
                          ? lang[getLanguage].PRODUCT_BILLED_MONTHLY
                          : lang[getLanguage].PRODUCT_BILLED_DAILY
                      }}
                    </span>
                  </div>
                  <span class="pricing-item-bonus">{{
                    getLanguage === "en" ? item.bonus.en : item.bonus.id
                  }}</span>
                  <v-radio label="" color="primary" :value="item"></v-radio>
                </v-card>
              </v-col>
            </v-row>
          </v-radio-group>
        </div>
        <!-- END LIST PLAN PRICE -->

        <span
          class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mt-2"
        ></span>

        <v-col col="12" md="12" class="text-title-subscription">
          <v-row>
            <span
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
              >{{ lang[getLanguage].PRODUCT_SUBSCRIBE_DETAIL }}</span
            >
          </v-row>
        </v-col>

        <v-col col="12" md="12" class="subscription-detail">
          <v-row v-if="selectedPricing != null">
            <p
              v-if="getLanguage === 'en'"
              class="text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              {{ selectedBike.name }} Subscription
            </p>
            <p
              v-else
              class="text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              Biaya Langganan Motor
              {{ selectedBike.name }}
            </p>
            <v-spacer></v-spacer>
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              Rp{{
                selectedUser === "driver"
                  ? formatPrice(selectedPricing.dailyPrice) +
                    "/" +
                    lang[getLanguage].LABEL_DAY
                  : formatPrice(selectedPricing.price) +
                    "/" +
                    lang[getLanguage].LABEL_MO
              }}
            </p>
          </v-row>
          <v-row v-if="selectedCharger !== null">
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              {{ selectedCharger.title }}
            </p>
            <v-spacer></v-spacer>
            <!-- <span class="text-info">Rp3.700/hari</span> -->
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              Rp{{
                selectedUser === "driver"
                  ? formatPrice(selectedCharger.dailyPrice) +
                    "/" +
                    lang[getLanguage].LABEL_DAY
                  : formatPrice(selectedCharger.price) +
                    "/" +
                    lang[getLanguage].LABEL_MO
              }}
            </p>
          </v-row>
          <v-row v-if="selectedBattery !== null">
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              {{
                getLanguage === "en"
                  ? selectedBattery.title.en
                  : selectedBattery.title.id
              }}
            </p>
            <v-spacer></v-spacer>
            <!-- <span class="text-info">Rp15.000/hari</span> -->
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              Rp{{
                selectedUser === "driver"
                  ? formatPrice(selectedBattery.dailyPrice) +
                    "/" +
                    lang[getLanguage].LABEL_DAY
                  : formatPrice(selectedBattery.price) +
                    "/" +
                    lang[getLanguage].LABEL_MO
              }}
            </p>
          </v-row>
          <v-row v-if="selectedRear !== null">
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              {{
                getLanguage === "en"
                  ? selectedRear.conclusion.en
                  : selectedRear.conclusion.id
              }}
            </p>
            <v-spacer></v-spacer>
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              Rp{{ formatPrice(selectedRear.dailyPrice) }}/{{
                lang[getLanguage].LABEL_DAY
              }}
            </p>
          </v-row>
          <v-row>
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              {{ lang[getLanguage].LABEL_TAX }}
            </p>
            <v-spacer></v-spacer>
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              <!-- Rp0/{{
                selectedUser === "driver"
                  ? lang[getLanguage].LABEL_DAY
                  : lang[getLanguage].LABEL_MO
              }} -->
              Sudah termasuk
            </p>
          </v-row>
          <v-row>
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              {{ lang[getLanguage].LABEL_INSURANCE }}
            </p>
            <v-spacer></v-spacer>
            <p
              class="text-info text-sm md:text-base text-charged-grey-400 font-work-sans"
            >
              <!-- Rp0/{{
                selectedUser === "driver"
                  ? lang[getLanguage].LABEL_DAY
                  : lang[getLanguage].LABEL_MO
              }} -->
              Sudah termasuk
            </p>
          </v-row>
        </v-col>

        <v-layout>
          <v-col col="12" md="9" class="text-title">
            <v-row>
              <span
                class="text-sm md:text-lg font-semibold font-work-sans text-charged-grey-200"
              >
                {{
                  selectedUser === "driver"
                    ? getLanguage == "en"
                      ? "Total Subscription Cost per Day"
                      : "Total Biaya Langganan per Hari"
                    : getLanguage == "en"
                    ? "Total Subscription Cost per Month"
                    : "Total Biaya Langganan per Bulan"
                }}
              </span>
            </v-row>
          </v-col>
          <v-col col="12" md="3" class="text-title font-semibold">
            <v-row>
              <v-spacer></v-spacer>
              <span class="text-info text-sm md:text-lg font-semibold">
                Rp
                {{ formatPrice(totalPrice) }}/{{
                  selectedUser === "driver"
                    ? lang[getLanguage].LABEL_DAY
                    : lang[getLanguage].LABEL_MO
                }}
              </span>
            </v-row>
          </v-col>
        </v-layout>
        <v-layout>
          <v-col cols="12" md="8" class="text-title pt-0">
            <v-row>
              <span
                class="text-info text-sm text-charged-grey-400 font-work-sans"
                >{{ lang[getLanguage].PRODUCT_MONTHLY_TOTAL_DESCRIPTION }}</span
              >
            </v-row>
          </v-col>
        </v-layout>

        <v-row class="mt-3">
          <v-col class="pb-0">
            <p
              class="text-sm md:text-lg font-semibold font-work-sans text-charged-grey-200"
            >
              Biaya Deposit
              <span class="cursor-pointer">
                <v-icon size="medium" @click="dialogDeposit = true"
                  >mdi-information-outline</v-icon
                >
              </span>
            </p>
          </v-col>
          <v-col class="pb-0">
            <p
              class="text-sm md:text-lg font-semibold font-work-sans text-right"
            >
              {{
                selectedUser === "driver"
                  ? "Rp 300.000"
                  : "Rp" + formatPrice(selectedPricing.price)
              }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8" class="pt-0">
            <p
              v-if="getLanguage == 'id'"
              class="font-work-sans text-sm font-normal text-charged-grey-400"
            >
              Ini adalah biaya deposit yang perlu dibayar sebelum memulai
              berlangganan motor Charged. Deposit hanya dibayarkan satu kali
              saat pengambilan motor dan bisa dikembalikan setelah berlangganan
              berakhir dengan ketentuan berlaku.
            </p>
            <p
              v-if="getLanguage == 'en'"
              class="font-work-sans text-sm font-normal text-charged-grey-400"
            >
              This is a deposit fee that needs to be paid before starting a
              Charged motorcycle subscription. The deposit is only paid once
              when picking up the motorbike and can be returned after the
              subscription ends with the conditions in effect.
            </p>
          </v-col>
        </v-row>

        <hr style="border-top: 1px dashed #c1c2c4" class="hidden md:block" />
        <span
          class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-2 mt-4"
        ></span>

        <!-- <span class="hidden w-full border border-dashed md:inline-block"></span> -->

        <!-- Mobile -->
        <div class="flex justify-end mt-3 md:hidden">
          <v-btn
            class="capitalize font-work-sans font-medium text-base rounded-lg"
            color="primary"
            block
            :disabled="isValid()"
            @click="stepFiveComplete"
          >
            {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <!-- Dekstop -->
        <div class="md:flex justify-end mt-3 hidden">
          <v-btn
            class="capitalize font-work-sans font-medium text-base rounded-lg"
            color="primary"
            right
            :disabled="isValid()"
            @click="stepFiveComplete"
          >
            {{ lang[getLanguage].BUT_COMMON_CONTINUE }}
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDeposit" persistent max-width="650">
      <v-card class="p-4">
        <v-card-title class="text-2xl text-h5 font-bold font-montserrat">
          {{ getLanguage == "en" ? "Deposit Fee" : "Biaya Deposit" }}
        </v-card-title>
        <v-card-text class="font-work-sans text-sm font-normal">
          <p>
            {{
              getLanguage == "en"
                ? "This payment helps us to process your order. Deposit payments are fully refundable at the end of the subscription period."
                : "Pembayaran ini membantu kami untuk memproses pesanan Anda. Pembayaran deposit dapat dikembalikan sepenuhnya pada akhir periode berlangganan."
            }}
          </p>
          <div class="flex mb-4">
            <div class="w-5">1.</div>
            <div class="w-full">
              {{
                getLanguage == "en"
                  ? "By providing this deposit, the customer acknowledges the subscription order for the selected vehicle model."
                  : "Dengan memberikan deposit ini, pelanggan mengakui pesanan berlangganan untuk model kendaraan yang dipilih."
              }}
            </div>
          </div>

          <div class="flex mb-4">
            <div class="w-5">2.</div>
            <div class="w-full">
              {{
                getLanguage == "en"
                  ? "After the subscription period ends, the deposit will be returned to the customer (maximum 5 working days), when the vehicle has passed the inspection process by our team."
                  : "Setelah masa berlangganan berakhir, deposit akan dikembalikan kepada pelanggan (maksimal 5 hari kerja), ketika kendaraan telah melewati proses pemeriksaan oleh tim kami."
              }}
            </div>
          </div>
          <div class="flex mb-4">
            <div class="w-5">3.</div>
            <div class="w-full">
              {{
                getLanguage == "en"
                  ? "However, if damage is found to the vehicle, the refund of the deposit will be deducted according to the cost of repairs."
                  : "Namun jika ditemukan kerusakan pada kendaraan, maka pengembalian deposit akan dipotong sesuai biaya perbaikan."
              }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-btn
            class="mx-auto capitalize font-work-sans font-medium"
            depressed
            color="primary"
            @click="dialogDeposit = false"
          >
            {{ getLanguage == "en" ? "I Understand" : "Saya Mengerti" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { getCurrentPlantApi } from "../../../../services/back-end-ruby";
import { postSetPlansApi } from "../../../../services/back-end-ruby/Vehicle";
export default {
  name: "form-subs-step-5",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean,
    windowHeight: Number,
    index: Number,
    profile: Object,
    step: Number,
    stepFiveComplete: Function,
    backStep: Function,
  },
  data() {
    return {
      dialogDeposit: false,
      lang: this.$store.state.lang,
      selectedBike: null,
      selectedPricing: null,
      selectedBattery: null,
      selectedCharger: null,
      selectedRear: null,
      loadingBtnStep: false,
      // data akun bank
      bankName: "",
      bankNameOthers: null,
      bankNameItems: [
        {
          title: "BNI",
          value: "BNI",
        },
        {
          title: "Mandiri",
          value: "Mandiri",
        },
        {
          title: "Permata",
          value: "Permata",
        },
        {
          title: "BSI",
          value: "BSI",
        },
        {
          title: "Sampoerna",
          value: "Sampoerna",
        },
        {
          title: "BJB",
          value: "BJB",
        },
        {
          title: "BRI",
          value: "BRI",
        },
        {
          title: "Bank Lainnya",
          value: "Bank Lainnya",
        },
      ],
      accountBankNumber: "",
      accountHoldersName: "",
      // data akun bank

      listPricing: [],
      selectedUser: "driver",
      totalPrice: 0,
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    getServiceCity() {
      return this.$store.state.user.serviceCity;
    },
    getProfile() {
      return this.$store.getters.getProfile;
    },
    getListVehicle() {
      return this.$store.getters.getListVehicle;
    },
    getListBatteryVehicle() {
      return this.$store.getters.getListBatteryVehicle;
    },
    getDataGojekKostumisasi() {
      return this.$store.getters.getDataGojekKostumisasi;
    },
    getSelectedVehiclePrice() {
      return this.$store.getters.getSelectedVehiclePrice;
    },
    getListVehiclePrice() {
      return this.$store.getters.getListVehiclePrice;
    },
    getDataGojekPlanSubs() {
      return this.$store.getters.getDataGojekPlanSubs;
    },
  },
  mounted() {
    // this.selectPricing(this.getListVehiclePrice[0]);
    this.getData();
  },
  created() {
    // this.listPricing = this.getListVehiclePrice;
    this.$store.commit("setDataGojekStepDone", false);
  },
  methods: {
    getData() {
      const dataPlans = this.getDataGojekPlanSubs;
      this.selectPricing(this.getListVehiclePrice[0]);
      this.listPricing = this.getListVehiclePrice;
      this.selectedBike = this.getDataGojekKostumisasi.bike;
      this.selectedBattery = this.getDataGojekKostumisasi.qtyBattery;
      this.selectedCharger = this.getDataGojekKostumisasi.typeCharge;
      this.selectedRear = this.getDataGojekKostumisasi.accessories ?? null;
      // console.log("CUSTOM", this.getDataGojekKostumisasi);

      let findBankName = this.bankNameItems.find(
        (b) => b.value == dataPlans.bankName
      );
      this.bankName =
        findBankName && dataPlans.bankName
          ? dataPlans.bankName
          : "Bank Lainnya";
      this.bankNameOthers =
        findBankName && dataPlans.bankName ? null : dataPlans.bankName;
      this.accountBankNumber = dataPlans.accountBankNumber;
      this.accountHoldersName = dataPlans.accountHoldersName;
    },
    async getDataApi() {
      await getCurrentPlantApi(
        this.$cookies.get("token_api"),
        this.$store.getters.getIdSubs
      ).then((res) => {
        // console.log("STEP_5", res.data);

        if (res.data.subscription.plans_qty) {
          // this.selectPricing(this.listPricing.find(priod => priod.))
          let getPricePriode = this.listPricing.find((e) =>
            e.plan.id.includes(res.data.subscription.plans_qty)
          );
          // console.log(
          //   this.listPricing.find((e) =>
          //     e.plan.id.includes(res.data.subscription.plans_qty)
          //   )
          // );

          this.selectPricing(getPricePriode);
        }
        // res.data.user.profile.bank
      });
    },
    selectPricing(item) {
      this.selectedPricing = item;
      // console.log("SELECTED_PRICE", this.selectedPricing);
      this.countTotalPrice();
    },
    countTotalPrice() {
      var batteryPrice = 0;
      var chargerPrice = 0;
      var bikePrice = 0;
      var rear = 0;
      let totalPrice = 0;

      if (this.selectedUser === "driver") {
        if (this.selectedBattery != null) {
          batteryPrice = parseInt(this.selectedBattery.dailyPrice);
        }
        if (this.selectedCharger != null) {
          chargerPrice = parseInt(this.selectedCharger.dailyPrice);
        }
        if (this.selectedPricing != null) {
          bikePrice = parseInt(this.selectedPricing.dailyPrice);
        }
        if (this.selectedRear != null) {
          rear = parseInt(this.selectedRear.dailyPrice);
        }
        totalPrice = batteryPrice + chargerPrice + bikePrice + rear;
      } else {
        if (this.selectedBattery != null) {
          batteryPrice = parseInt(this.selectedBattery.price);
        }
        if (this.selectedCharger != null) {
          chargerPrice = parseInt(this.selectedCharger.price);
        }
        if (this.selectedPricing != null) {
          bikePrice = parseInt(this.selectedPricing.price);
        }
        totalPrice = batteryPrice + chargerPrice + bikePrice;
      }
      this.totalPrice = `${totalPrice}`;
    },
    async submit() {
      this.loadingBtnStep = true;
      let token = this.$cookies.get("token_api");
      await postSetPlansApi(token, {
        subscription_id: this.$store.getters.getIdSubs,
        plans_id: this.selectedPricing.id,
        bank_name:
          this.bankName == "Bank Lainnya" ? this.bankNameOthers : this.bankName,
        holder_name: this.accountHoldersName,
        account_number: this.accountBankNumber,
      }).then((res) => {
        if (res.meta.code == 200) {
          // this.stepper = 6;
          // this.this.sendPlanPrice = true;
          this.loadingBtnStep = false;
          this.$store.commit("setSelectedVehiclePrice", this.selectedPricing);
          this.$store.commit("setTotalAmount", this.totalPrice);
          this.$store.commit("setDataGojekPlanSubs", {
            bankName:
              this.bankName == "Bank Lainnya"
                ? this.bankNameOthers
                : this.bankName,
            accountBankNumber: this.accountBankNumber,
            accountHoldersName: this.accountHoldersName,
          });
          this.$store.commit("setDataGojekStepDone", true);
        } else {
          this.$store.commit("setDataGojekStepDone", false);
        }
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    isValid() {
      return (
        this.loadingBtnStep ||
        this.bankName == "" ||
        this.accountBankNumber == "" ||
        this.accountHoldersName == "" ||
        this.selectedPricing == null
      );
    },
  },
};
</script>