<template>
  <div>
    <v-layout column v-if="loading">
      <p class="mt-0"></p>

      <v-layout column>
        <v-spacer></v-spacer>
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          width="100%"
        ></v-skeleton-loader>
        <v-spacer></v-spacer>
      </v-layout>
    </v-layout>
    <v-row v-else>
      <v-col cols="12" md="9">
        <v-col class="stepBack pt-0">
          <v-btn
            class="pl-0 md:pl-4"
            @click.stop="goToForm(1)"
            text
            color="primary"
            :ripple="false"
            style="font-weight: 600"
          >
            <v-icon color="primary" class="mr-1">mdi-chevron-left</v-icon
            >{{ lang[getLanguage].BUT_BACK }}
          </v-btn>
        </v-col>
        <v-card flat mb-4>
          <!-- START PERSONAL INFO -->
          <span id="stepTitle" class="stepTitle">{{
            lang[getLanguage].TEST_DRIVE_PERSONAL_DATA
          }}</span>

          <v-row class="mt-6">
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title">{{
                lang[getLanguage].LABEL_FULL_NAME_KTP
              }}</span>
              <v-text-field
                class="personal-input-value custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                placeholder="John Doe"
                v-model="personalInfo.name"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-6">
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title">
                {{ "NIK *" }}
              </span>
              <v-text-field
                class="personal-input-value custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                placeholder="contoh: 3674000000"
                v-model="personalInfo.nik"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title">{{
                lang[getLanguage].SUBSCRIBE_EMAIL_ADDRESS
              }}</span>
              <v-text-field
                class="personal-input-value custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="personalInfo.emailPribadi"
                placeholder="johndoe@gmail.com"
                :rules="emailRules"
                type="email"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title">{{
                lang[getLanguage].SUBSCRIBE_PHONE_NUMBER
              }}</span>
              <v-text-field
                class="personal-input-value custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                placeholder="e.g. +624718297372"
                filled
                v-model="personalInfo.phoneNumberPribadi"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title"> Tempat Lahir * </span>
              <v-text-field
                class="personal-input-value custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                v-model="personalInfo.tempatLahir"
                placeholder="contoh: Jakarta"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <span class="personal-input-title"> Tanggal Lahir * </span>
              <v-text-field
                class="personal-input-value custom-combobox-d"
                style="width: 100%"
                outlined
                dense
                filled
                type="date"
                v-model="personalInfo.dateTglLahir"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-layout
            style="width: 100%; margin-top: -1.5rem; margin-bottom: 2.5rem"
          >
            <span class="customization-item-desc">{{
              lang[getLanguage].TEST_DRIVE_CONTACT_DESCRIPTION
            }}</span>
          </v-layout>
          <!-- END PERSONAL INFO -->

          <hr
            style="border-top: 1px dashed #c1c2c4; margin-top: 1rem"
            class="hidden md:block"
          />
          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full"
          ></span>

          <!-- START PARENT INFO -->
          <v-layout class="mt-6">
            <span class="stepTitle">{{
              lang[getLanguage].SUBSCRIBE_PARENT_TITLE
            }}</span>
          </v-layout>

          <v-layout class="mt-6" style="width: 100%">
            <v-row>
              <v-col cols="12" md="6">
                <span class="personal-input-title">{{
                  lang[getLanguage].SUBSCRIBE_MOTHER_LABEL
                }}</span>
                <v-text-field
                  class="personal-input-value custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="lang[getLanguage].SUBSCRIBE_MOTHER_PLACEHOLDER"
                  v-model="personalInfo.motherName"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-layout>

          <hr
            style="border-top: 1px dashed #c1c2c4; margin-top: 1rem"
            class="hidden md:block"
          />
          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full"
          ></span>

          <!-- START POSITION INFO -->
          <v-layout class="mt-6">
            <span class="stepTitle">{{
              lang[getLanguage].SUBSCRIBE_PROFESSION_TITLE
            }}</span>
          </v-layout>

          <v-layout class="mt-6" style="width: 100%">
            <v-row>
              <v-col cols="12" md="6">
                <span class="personal-input-title">{{
                  lang[getLanguage].SUBSCRIBE_COMPANY_LABEL
                }}</span>
                <v-text-field
                  class="personal-input-value custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="lang[getLanguage].SUBSCRIBE_COMPANY_PLACEHOLDER"
                  v-model="personalInfo.companyName"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-layout>

          <v-layout
            style="width: 100%"
            v-if="CUSTOMER_TYPE.regular.includes(selectedUser)"
          >
            <v-row>
              <v-col cols="12" md="6">
                <span class="personal-input-title">{{
                  lang[getLanguage].SUBSCRIBE_JOB_POSITION_LABEL
                }}</span>
                <v-select
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="
                    lang[getLanguage].SUBSCRIBE_JOB_POSITION_PLACEHOLDER
                  "
                  v-model="personalInfo.jobPosition"
                  :items="
                    getLanguage === 'en'
                      ? PERSONAL_INFOS['jobPositionListEnglish']
                      : PERSONAL_INFOS['jobPositionListIndo']
                  "
                  item-text="title"
                  item-value="value"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <span class="personal-input-title">
                  {{ lang[getLanguage].SUBSCRIBE_EMPLOYMENT_STATUS_LABEL }}
                </span>
                <v-select
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="
                    lang[getLanguage].SUBSCRIBE_EMPLOYMENT_STATUS_PLACEHOLDER
                  "
                  v-model="personalInfo.employmentStatus"
                  :items="
                    getLanguage === 'en'
                      ? PERSONAL_INFOS['employmentStatusListEnglish']
                      : PERSONAL_INFOS['employmentStatusListIndo']
                  "
                  item-text="title"
                  item-value="value"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-layout>

          <v-layout
            style="width: 100%"
            v-if="
              CUSTOMER_TYPE.driver.includes(selectedUser) && !isMobileDevice
            "
          >
            <v-row>
              <v-col cols="12" md="6">
                <span class="personal-input-title">
                  {{ lang[getLanguage].SUBSCRIBE_WORK_LENGTH_LABEL }}
                </span>
                <v-select
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="
                    lang[getLanguage].SUBSCRIBE_WORK_LENGTH_PLACEHOLDER
                  "
                  v-model="personalInfo.yearOfExperience"
                  :items="
                    getLanguage === 'en'
                      ? PERSONAL_INFOS['yearOfExperienceListEnglish']
                      : PERSONAL_INFOS['yearOfExperienceListIndo']
                  "
                  item-text="title"
                  item-value="value"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <span class="personal-input-title">
                  {{ lang[getLanguage].SUBSCRIBE_INCOME_LABEL }}
                </span>
                <v-select
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="personalInfo.salary"
                  :placeholder="lang[getLanguage].SUBSCRIBE_INCOME_PLACEHOLDER"
                  :items="PERSONAL_INFOS['itemsSalary']"
                  item-text="title"
                  item-value="value"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-layout>

          <v-layout
            style="width: 100%"
            v-if="
              CUSTOMER_TYPE.regular.includes(selectedUser) && !isMobileDevice
            "
          >
            <v-row>
              <v-col cols="12" md="6">
                <span class="personal-input-title">{{
                  lang[getLanguage].SUBSCRIBE_WORK_LENGTH_LABEL
                }}</span>
                <v-select
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  :placeholder="
                    lang[getLanguage].SUBSCRIBE_WORK_LENGTH_PLACEHOLDER
                  "
                  v-model="personalInfo.yearOfExperience"
                  :items="
                    getLanguage === 'en'
                      ? PERSONAL_INFOS['yearOfExperienceListEnglish']
                      : PERSONAL_INFOS['yearOfExperienceListIndo']
                  "
                  item-text="title"
                  item-value="value"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-layout>

          <v-layout
            style="width: 100%"
            v-if="CUSTOMER_TYPE.regular.includes(selectedUser)"
          >
            <v-row>
              <v-col cols="12" md="6">
                <span class="personal-input-title">{{
                  lang[getLanguage].SUBSCRIBE_INCOME_LABEL
                }}</span>
                <v-select
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="personalInfo.salary"
                  :placeholder="lang[getLanguage].SUBSCRIBE_INCOME_PLACEHOLDER"
                  :items="PERSONAL_INFOS['itemsSalary']"
                  item-text="title"
                  item-value="value"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <span class="personal-input-title">{{
                  lang[getLanguage].SUBSCRIBE_CC_LABEL
                }}</span>
                <v-select
                  class="custom-combobox-d"
                  style="width: 100%"
                  outlined
                  dense
                  filled
                  v-model="personalInfo.creditCardLimit"
                  :placeholder="lang[getLanguage].SUBSCRIBE_CC_PLACEHOLDER"
                  :items="PERSONAL_INFOS['creditCardLimit']"
                  item-text="title"
                  item-value="value"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-layout>
          <!-- END PROFESSION INFO -->

          <hr
            style="border-top: 1px dashed #c1c2c4"
            class="hidden md:block md:mb-5"
          />
          <span
            class="md:hidden inline-block h-2 bg-charged-grey-700 w-full mb-4"
          ></span>

          <!-- START MARITAL STATUS INFO -->
          <v-layout>
            <span class="stepTitle">{{
              lang[getLanguage].SUBSCRIBE_MARITAL_STATUS_TITLE
            }}</span>
          </v-layout>
          <v-layout class="mt-6">
            <span class="personal-input-title">{{
              lang[getLanguage].SUBSCRIBE_MARITAL_STATUS_LABEL
            }}</span>
          </v-layout>
          <v-layout
            class="selection mt-4"
            style="justify-content: space-between"
            v-if="getLanguage === 'id' && isMobileDevice"
          >
            <div class="selection" @click.stop="selectMaritalStatus('single')">
              <v-icon
                color="#353638"
                style="padding-right: 0.5rem"
                v-if="personalInfo.maritalStatus !== 'single'"
                >mdi-radiobox-blank
              </v-icon>
              <v-icon
                color="#6b4693"
                style="padding-right: 0.5rem"
                v-if="personalInfo.maritalStatus === 'single'"
                >mdi-radiobox-marked
              </v-icon>
              <label class="personal-item-label">
                {{ lang[getLanguage].SUBSCRIBE_SINGLE_LABEL }}
                <!-- Belum Menikah -->
              </label>
            </div>
            <div class="selection" @click.stop="selectMaritalStatus('married')">
              <v-icon
                color="#353638"
                style="padding-right: 0.5rem"
                v-if="personalInfo.maritalStatus !== 'married'"
                >mdi-radiobox-blank
              </v-icon>
              <v-icon
                color="#6b4693"
                style="padding-right: 0.5rem"
                v-if="personalInfo.maritalStatus === 'married'"
                >mdi-radiobox-marked
              </v-icon>
              <label class="personal-item-label">
                {{ lang[getLanguage].SUBSCRIBE_MARIED_LABEL }}
                <!-- Sudah Menikah -->
              </label>
            </div>
          </v-layout>

          <v-row
            class="selection mt-4"
            v-if="getLanguage === 'en' || !isMobileDevice"
          >
            <v-col>
              <div class="" @click.stop="selectMaritalStatus('single')">
                <v-icon
                  color="#353638"
                  style="padding-right: 0.5rem"
                  v-if="personalInfo.maritalStatus !== 'single'"
                  >mdi-radiobox-blank
                </v-icon>
                <v-icon
                  color="#6b4693"
                  style="padding-right: 0.5rem"
                  v-if="personalInfo.maritalStatus === 'single'"
                  >mdi-radiobox-marked
                </v-icon>
                <label
                  style="
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #353638;
                  "
                >
                  {{ lang[getLanguage].SUBSCRIBE_SINGLE_LABEL }}
                  <!-- Belum Menikah -->
                </label>
              </div></v-col
            >
            <v-col
              ><div class="" @click.stop="selectMaritalStatus('married')">
                <v-icon
                  color="#353638"
                  style="padding-right: 0.5rem"
                  v-if="personalInfo.maritalStatus !== 'married'"
                  >mdi-radiobox-blank
                </v-icon>
                <v-icon
                  color="#6b4693"
                  style="padding-right: 0.5rem"
                  v-if="personalInfo.maritalStatus === 'married'"
                  >mdi-radiobox-marked
                </v-icon>
                <label
                  style="
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #353638;
                  "
                >
                  {{ lang[getLanguage].SUBSCRIBE_MARIED_LABEL }}
                  <!-- Sudah Menikah -->
                </label>
              </div></v-col
            >
          </v-row>

          <div v-if="personalInfo.maritalStatus === 'married'">
            <v-layout class="mt-6" style="width: 100%">
              <v-row>
                <v-col cols="12" md="6" class="pb-0">
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_JOB_POSITION_LABEL
                  }}</span>
                  <v-select
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="
                      lang[getLanguage].SUBSCRIBE_JOB_POSITION_PLACEHOLDER
                    "
                    v-model="personalInfo.spousePositionLabel"
                    :items="
                      getLanguage === 'en'
                        ? PERSONAL_INFOS['spousePositionListEnglish']
                        : PERSONAL_INFOS['spousePositionListIndo']
                    "
                    item-text="title"
                    item-value="value"
                    @change="selectSpousePosition()"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-layout>
            <v-layout
              v-if="personalInfo.spousePositionLabel !== 'housewife'"
              style="width: 100%"
            >
              <v-row class="mt-3">
                <v-col cols="12" md="6" class="py-0">
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_COMPANY_NAME_LABEL
                  }}</span>
                  <v-text-field
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="
                      lang[getLanguage]
                        .SUBSCRIBE_SPOUSE_COMPANY_NAME_PLACEHOLDER
                    "
                    v-model="personalInfo.spouseCompanyName"
                  >
                  </v-text-field>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                  v-if="personalInfo.spousePositionLabel !== 'housewife'"
                >
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_EMPLOYMENT_STATUS_LABEL
                  }}</span>
                  <v-select
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="
                      lang[getLanguage].SUBSCRIBE_EMPLOYMENT_STATUS_PLACEHOLDER
                    "
                    v-model="personalInfo.spouseEmploymentStatus"
                    :items="
                      getLanguage === 'en'
                        ? PERSONAL_INFOS['employmentStatusListEnglish']
                        : PERSONAL_INFOS['employmentStatusListIndo']
                    "
                    item-text="title"
                    item-value="value"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-layout>

            <v-layout
              v-if="personalInfo.spousePositionLabel !== 'housewife'"
              style="width: 100%"
            >
              <v-row class="mt-3">
                <v-col cols="12" md="6" class="py-0">
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_LABEL
                  }}</span>
                  <v-select
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    :placeholder="
                      lang[getLanguage]
                        .SUBSCRIBE_SPOUSE_YEAR_OF_EXPERIENCE_PLACEHOLDER
                    "
                    v-model="personalInfo.spouseYearOfExperience"
                    :items="
                      getLanguage === 'en'
                        ? PERSONAL_INFOS['yearOfExperienceListEnglish']
                        : PERSONAL_INFOS['yearOfExperienceListIndo']
                    "
                    item-text="title"
                    item-value="value"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <span class="personal-input-title">{{
                    lang[getLanguage].SUBSCRIBE_SPOUSE_SALARY_LABEL
                  }}</span>
                  <v-select
                    class="custom-combobox-d"
                    style="width: 100%"
                    outlined
                    dense
                    filled
                    v-model="personalInfo.spouseSalary"
                    :placeholder="
                      lang[getLanguage].SUBSCRIBE_SPOUSE_SALARY_PLACEHOLDER
                    "
                    :items="PERSONAL_INFOS['itemsSalary']"
                    item-text="title"
                    item-value="value"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-layout>
          </div>
          <!-- END MARITAL STATUS INFO -->

          <hr
            style="border-top: 1px dashed #c1c2c4; margin-top: 1rem"
            class="hidden md:block"
          />

          <!-- Mobile -->
          <div class="flex justify-end mt-3 md:hidden">
            <v-btn
              class="capitalize font-work-sans font-medium text-base rounded-lg"
              color="primary"
              block
              :disabled="isValid()"
              :loading="loadingBtnStep"
              @click="handleSubmitStepTwo"
            >
              Lanjutkan
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <!-- Dekstop -->
          <div class="md:flex justify-end mt-3 hidden">
            <v-btn
              class="capitalize font-work-sans font-medium text-base rounded-lg"
              color="primary"
              right
              :disabled="isValid()"
              :loading="loadingBtnStep"
              @click="handleSubmitStepTwo"
            >
              Lanjutkan
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  formatPrice,
  formatDate,
} from "../../../../store/form-subscribe/data/non-gojek/utils";
import {
  CUSTOMER_TYPE,
  PERSONAL_INFOS,
} from "../../../../store/form-subscribe/data/non-gojek/const";
import {
  getProfileApi,
  postPersonalInfoApi,
} from "../../../../services/back-end-ruby";

export default {
  name: "step-2",
  props: {
    windowWidth: Number,
    isMobileDevice: Boolean,
    goToForm: Function,
    handleSubmitStepTwo: Function,
  },
  data() {
    return {
      formatPrice,
      formatDate,
      CUSTOMER_TYPE,
      loading: false,
      lang: this.$store.state.lang,
      loadingData: false,
      loadingScreen: false,
      PERSONAL_INFOS,
      stepDone: false,
      menuTanggalLahir: false,
      loadingBtnStep: false,
      sendPersonalInfo: false,
      emailRules: [],
      address: "",
      selectedUser: "",
      personalInfo: {
        name: "",
        nik: "",
        emailPribadi: "",
        phoneNumberPribadi: "",
        tempatLahir: "",
        dateTglLahir: "",
        motherName: "",
        companyName: "",
        employmentStatus: "",
        jobPosition: "",
        yearOfExperience: "",
        salary: "",
        creditCardLimit: "",
        maritalStatus: "",
        spouseCompanyName: "",
        spousePositionLabel: "",
        spouseEmploymentStatus: "",
        spouseYearOfExperience: "",
        spouseSalary: "",
      },
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  computed: {
    getUser() {
      return this.$store.state.user;
    },
    getLanguage() {
      return this.$store.state.user.language;
    },
    getDataInformasiPribadi() {
      return this.$store.getters.getDataInformasiPribadi;
    },
  },
  async mounted() {},
  async created() {
    this.loading = true;
    await this.initUser();
    this.$store.commit("setDataStepDone", false);
    this.emailRules = [
      (v) => !!v || this.lang[this.getLanguage].VALIDATION_EMAIL_REQ,
      (v) =>
        /.+@.+\..+/.test(v) ||
        this.lang[this.getLanguage].VALIDATION_EMAIL_VALID,
      (v) =>
        (v || "").indexOf(" ") < 0 ||
        this.lang[this.getLanguage].VALIDATION_EMAIL_VALID,
    ];
    this.loading = false;
  },
  methods: {
    async initUser() {
      this.currentUser = await this.getUser;
      this.selectedUser = this.currentUser.customerType;
    },
    async getData() {
      this.personalInfo = await this.getDataInformasiPribadi;
    },
    selectMaritalStatus(item) {
      this.personalInfo.maritalStatus = "";
      this.personalInfo.maritalStatus = item;
      if (item === "married") {
        this.personalInfo.spousePositionLabel = "";
        this.personalInfo.spouseEmploymentStatus = "";
        this.personalInfo.spouseCompanyName = "";
        this.personalInfo.spouseYearOfExperience = "";
        this.personalInfo.spouseSalary = "";
      }
    },
    selectSpousePosition() {
      if (
        this.personalInfo.spousePositionLabel === "Housewife" ||
        this.personalInfo.spousePositionLabel === "Ibu Rumah Tangga"
      ) {
        this.personalInfo.spouseEmploymentStatus = "";
        this.personalInfo.spouseCompanyName = "";
        this.personalInfo.spouseYearOfExperience = "";
        this.personalInfo.spouseSalary = "";
      }
    },
    async submit() {
      this.$store.commit("setDataInformasiPribadi", this.personalInfo);

      const token = this.$cookies.get("token_api");
      const idSubs = this.$store.getters.getIdSubs;

      let opsiSingle = {
        company_name: this.personalInfo.companyName,
        job_position: this.personalInfo.jobPosition,
        employment_status: this.personalInfo.employmentStatus,
        length_of_work: this.personalInfo.yearOfExperience,
        profit_income: this.personalInfo.salary,
        credit_card_limit: this.personalInfo.creditCardLimit,
        employment_type: "user",
      };

      let opsiMaried = {
        company_name: this.personalInfo.spouseCompanyName,
        job_position: this.personalInfo.spousePositionLabel,
        employment_status: this.personalInfo.spouseEmploymentStatus,
        length_of_work: this.personalInfo.spouseYearOfExperience,
        profit_income: this.personalInfo.spouseSalary,
        credit_card_limit: "",
        employment_type: "spouse",
      };

      let compareOpsi =
        this.personalInfo.maritalStatus === "single"
          ? [opsiSingle]
          : [opsiSingle, opsiMaried];

      await postPersonalInfoApi(token, {
        subscription_id: idSubs,
        guardian_name: this.personalInfo.motherName,
        marital_status: this.personalInfo.maritalStatus,
        nik: this.personalInfo.nik,
        birth_place: this.personalInfo.tempatLahir,
        birth_date: this.formatDate(this.personalInfo.dateTglLahir),
        employment: compareOpsi,
        email: this.personalInfo.emailPribadi,
        phone_number: this.personalInfo.phoneNumberPribadi,
      }).then((res) => {
        if (res.meta.code == 200) {
          this.sendPersonalInfo = true;

          getProfileApi(token).then((res) => {
            this.$store.commit("setProfile", res.data.user);

            this.$store.commit("setVehiclePrice", {
              token,
              customer_type: res.data.user.customer_type,
              subscription_id: idSubs,
              reset: true,
            });
          });

          this.$store.commit("setDataStepDone", true);
          this.loadingBtnStep = false;
        } else {
          this.$store.commit("setDataStepDone", false);
        }
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    isAccEmpty() {
      return false;
    },
    isValid() {
      return (
        this.loadingBtnStep ||
        this.personalInfo.name === "" ||
        this.personalInfo.phoneNumberPribadi === "" ||
        this.personalInfo.motherName === "" ||
        this.personalInfo.yearOfExperience === "" ||
        this.personalInfo.salary === "" ||
        this.personalInfo.maritalStatus === "" ||
        (this.personalInfo.spousePositionLabel !== "housewife" &&
          this.personalInfo.maritalStatus === "married" &&
          this.personalInfo.spouseCompanyName === "") ||
        (this.personalInfo.spousePositionLabel !== "housewife" &&
          this.personalInfo.maritalStatus === "married" &&
          this.personalInfo.spouseEmploymentStatus === "") ||
        (this.personalInfo.spousePositionLabel !== "housewife" &&
          this.personalInfo.maritalStatus === "married" &&
          this.personalInfo.spouseYearOfExperience === "") ||
        (this.personalInfo.spousePositionLabel !== "housewife" &&
          this.personalInfo.maritalStatus === "married" &&
          this.personalInfo.spouseSalary === "")
      );
    },
  },
};
</script>

<style></style>