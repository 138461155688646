<template>
  <div>
    <v-layout column v-if="loading">
      <p class="mt-0"></p>

      <v-layout column>
        <v-spacer></v-spacer>
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          width="100%"
        ></v-skeleton-loader>
        <v-spacer></v-spacer>
      </v-layout>
    </v-layout>
    <v-row v-else>
      <v-col cols="12" md="8">
        <v-col class="stepBack pt-0">
          <v-btn
            class="pl-0 md:pl-4"
            @click.stop="goToForm(3)"
            text
            color="primary"
            :ripple="false"
            style="font-weight: 600"
          >
            <v-icon color="primary" class="mr-1">mdi-chevron-left</v-icon
            >{{ lang[getLanguage].BUT_BACK }}
          </v-btn>
        </v-col>

        <span class="stepTitle">{{
          lang[getLanguage].SUBSCRIBE_PRIVATE_DOCUMENT_TTILE
        }}</span>

        <v-col col="12" md="10" class="text-title">
          <v-row>
            <span class="text-info">
              {{ lang[getLanguage].SUBSCRIBE_PRIVATE_DOCUMENT_DESCRIPTION }}
            </span>
          </v-row>
        </v-col>

        <div v-for="(item, index) in file" :key="index.label">
          <div v-if="file[`${index}`].user.includes(selectedUser)" class="mt-5">
            <span
              class="hidden w-full border border-dashed md:inline-block"
            ></span>
            <span
              class="md:hidden inline-block h-2 bg-charged-grey-700 w-full"
            ></span>
            <v-layout class="mt-6" style="width: 100%">
              <v-layout column class="relative">
                <span
                  class="text-base text-charged-grey-400 font-normal font-work-sans mb-3"
                  >{{
                    getLanguage === "en"
                      ? file[`${index}`].label.en
                      : file[`${index}`].label.id
                  }}</span
                >
                <v-btn
                  class="w-56 capitalize rounded-lg relative z-10"
                  color="primary"
                  outlined
                  v-if="file[`${index}`].file === null"
                  @click="fileInputClick(index)"
                  ><v-icon style="color: #6b4693" left> mdi-plus </v-icon
                  >{{
                    lang[getLanguage].SUBSCRIBE_PRIVATE_DOCUMENT_UPLOAD_LABEL
                  }}</v-btn
                >
                <v-row
                  v-if="file[`${index}`].file !== null"
                  class="relative z-10"
                >
                  <v-col cols="10">
                    <v-layout v-if="file[`${index}`].file !== null">
                      <v-row>
                        <!-- <div style="padding: 16px">
                          <div
                            v-if="file[`${index}`].onUpload.loading"
                            class="custom-mounted-file-btn"
                          >
                            <div class="d-flex align-center">
                              <v-progress-circular
                                class="mr-4"
                                size="30"
                                color="primary"
                                indeterminate
                              ></v-progress-circular>
                              <div
                                class="text-truncate"
                                :style="isMobileDevice && 'max-width: 150px'"
                              >
                                <div>
                                  {{ file[`${index}`]["file"]["name"] }}
                                </div>
                                <div v-if="!isMobileDevice">
                                  <v-progress-linear
                                    v-model="file[`${index}`].onUpload.value"
                                    :style="
                                      !isMobileDevice &&
                                      'width: 400px !important'
                                    "
                                    color="#4F3379"
                                  ></v-progress-linear>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex justify-end">
                              <div
                                class="mx-2 border-none m-0 p-0 cursor-pointer"
                                @click="fileDelete(index)"
                              >
                                <v-icon color="#6B4693">
                                  mdi-delete-outline
                                </v-icon>
                              </div>
                            </div>
                          </div>
                          <div class="custom-mounted-file-btn" v-else>
                            <v-icon class="mr-1" @click="fileInputClick(index)">
                              mdi-image-edit-outline
                            </v-icon>
                            <span
                              class="text-truncate"
                              :style="isMobileDevice && 'max-width: 150px'"
                              @click="fileInputClick(index)"
                            >
                              {{ file[`${index}`]["file"]["name"] }}
                            </span>
                            <v-spacer></v-spacer>
                            <div class="d-flex justify-end">
                              <div
                                class="mx-2 border-none m-0 p-0 cursor-pointer"
                                @click="fileDelete(index)"
                              >
                                <v-icon color="#6B4693">
                                  mdi-delete-outline
                                </v-icon>
                              </div>
                            </div>
                          </div>
                        </div> -->

                        <v-col>
                          <v-card
                            :width="
                              file[`${index}`].onUpload.loading ? 450 : 250
                            "
                            max-width="350"
                            elevation="0"
                            outlined
                            class="rounded-md p-4"
                          >
                            <v-row align="center">
                              <v-col cols="2">
                                <v-progress-circular
                                  v-if="file[`${index}`].onUpload.loading"
                                  :size="25"
                                  indeterminate
                                  color="purple"
                                ></v-progress-circular>
                                <v-icon
                                  v-if="!file[`${index}`].onUpload.loading"
                                  class="mr-1"
                                >
                                  mdi-image-outline
                                </v-icon>
                              </v-col>

                              <v-col cols="8">
                                <span
                                  class="text-truncate truncate block"
                                  :style="
                                    isMobileDevice
                                      ? 'max-width: 150px'
                                      : 'max-width: 150px'
                                  "
                                >
                                  {{ file[`${index}`]["file"]["name"] }}
                                </span>
                                <v-progress-linear
                                  class="rounded-lg"
                                  v-if="file[`${index}`].onUpload.loading"
                                  :value="file[`${index}`].onUpload.value"
                                ></v-progress-linear>
                              </v-col>

                              <v-col cols="2" class="text-end">
                                <v-icon
                                  :disabled="file[`${index}`].onUpload.loading"
                                  color="primary"
                                  @click="fileDelete(index)"
                                >
                                  mdi-delete-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                </v-row>
                <v-file-input
                  ref="refFilePicked"
                  class="style-file-input"
                  :rules="file[index].rulesFile"
                  accept="image/*"
                  :id="index"
                  @change="onFilePicked(index)"
                  v-model="file[`${index}`].file"
                ></v-file-input>
              </v-layout>
            </v-layout>
            <v-col
              col="12"
              md="10"
              class="text-title"
              v-if="file[`${index}`].file === null"
            >
              <v-row>
                <span
                  class="font-work-sans text-sm font-normal text-charged-grey-400"
                >
                  {{
                    lang[getLanguage]
                      .SUBSCRIBE_PRIVATE_DOCUMENT_ITEM__DESCRIPTION
                  }}
                </span>
              </v-row>
            </v-col>
            <v-col v-if="file[index].noted" cols="12" md="10" class="px-0">
              <span
                class="font-work-sans text-sm font-normal text-charged-grey-400"
              >
                {{ file[index].noted }}
              </span>
            </v-col>
          </div>
        </div>

        <hr
          style="border-top: 1px dashed #c1c2c4; margin-top: 1rem"
          class="hidden md:block"
        />

        <!-- Mobile -->
        <div class="flex justify-end mt-3 md:hidden">
          <v-btn
            class="capitalize font-work-sans font-medium text-base rounded-lg"
            color="primary"
            block
            :disabled="isValid()"
            :loading="loadingBtnStep"
            @click="handleSubmitStepFour"
          >
            Lanjutkan
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <!-- Dekstop -->
        <div class="md:flex justify-end mt-3 hidden">
          <v-btn
            class="capitalize font-work-sans font-medium text-base rounded-lg"
            color="primary"
            right
            :disabled="isValid()"
            :loading="loadingBtnStep"
            @click="handleSubmitStepFour"
          >
            Lanjutkan
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatPrice } from "../../../../store/form-subscribe/data/non-gojek/utils";
import { CUSTOMER_TYPE } from "../../../../store/form-subscribe/data/non-gojek/const";
import {
  postPrivateDocApi,
  getPrivateDocApi,
} from "../../../../services/back-end-ruby";

export default {
  name: "step-4",
  props: {
    windowWidth: Number,
    isMobileDevice: Boolean,
    goToForm: Function,
    handleSubmitStepFour: Function,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    uploadProgress() {
      const filteredFile = Object.keys(this.file).reduce((acc, key) => {
        const value = this.file[key];
        if (value.user.includes(this.selectedUser)) {
          acc[key] = value;
        }
        return acc;
      }, {});

      const totalFiles = Object.keys(filteredFile).length;
      if (totalFiles === 0) return 0;

      const totalProgress = Object.values(filteredFile).reduce(
        (total, file) => total + file.onUpload.value,
        0
      );

      return (totalProgress / (totalFiles * 100)) * 100;
    },
  },
  data() {
    return {
      formatPrice,
      CUSTOMER_TYPE,
      loading: false,
      loadingBtnStep: false,
      lang: this.$store.state.lang,
      validationStepFour: true,
      selectedUser: "",
      file: {
        identityCard: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          onUpload: {
            value: 0,
            loading: false,
          },
          user: ["mitra", "driver", "non_mitra", "regular"],
          label: { en: "KTP *", id: "KTP *" },
          file: null,
          isUploaded: false,
          key: "file_ktp",
        },
        familyRegistration: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          onUpload: {
            value: 0,
            loading: false,
          },
          user: ["mitra", "driver", "non_mitra", "regular"],
          label: { en: "KK *", id: "KK *" },
          file: null,
          isUploaded: false,
          key: "file_kk",
        },
        taxpayerIdentificationNumber: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          onUpload: {
            value: 0,
            loading: false,
          },
          user: ["mitra", "driver", "non_mitra", "regular"],
          label: { en: "NPWP (Optional)", id: "NPWP (Opsional)" },
          file: null,
          noted:
            "Note : Jika Anda belum bisa mengunggah NPWP, Anda bisa melampirkan NPWP pada saat sesi tanda tangan kontrak.",
          isUploaded: false,
          key: "file_npwp",
        },
        drivingLicense: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          onUpload: {
            value: 0,
            loading: false,
          },
          user: ["mitra", "driver", "non_mitra", "regular"],
          label: { en: "SIM C *", id: "SIM C *" },
          file: null,
          isUploaded: false,
          key: "file_sim_c",
        },
        dashboardDriver: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          onUpload: {
            value: 0,
            loading: false,
          },
          user: ["mitra", "driver"],
          label: {
            en: "Capture of Driver Dashboard Home Page *",
            id: "Capture Halaman Awal Dashboard Driver *",
          },
          file: null,
          isUploaded: false,
          key: "file_capture_dashboard_driver",
        },
        dashboardDriverIncomeOneMonth: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          onUpload: {
            value: 0,
            loading: false,
          },
          user: ["mitra", "driver"],
          label: {
            en: "Capture Dashboard Penghasilan Mitra Selama 1 Bulan *",
            id: "Capture Dashboard Penghasilan Mitra Selama 1 Bulan *",
          },
          file: null,
          isUploaded: false,
          key: "file_capture_dashboard_income_driver",
        },
        accountTransaction: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          onUpload: {
            value: 0,
            loading: false,
          },
          user: ["non_mitra", "regular"],
          label: {
            en: "Saving Account Transaction in the Last 3 Months *",
            id: "Transaksi Rekening Tabungan selama 3 bulan terakhir *",
          },
          file: null,
          isUploaded: false,
          key: "file_account_transaction",
        },
        payslip: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          onUpload: {
            value: 0,
            loading: false,
          },
          user: ["non_mitra", "regular"],
          label: { en: "Payslip (optional)", id: "Payslip (optional)" },
          file: null,
          isUploaded: false,
          key: "file_payslip",
        },
        domisili: {
          rulesFile: [
            (v) => !v || v.size < 2000000 || "size should be less than 2 MB!",
          ],
          onUpload: {
            value: 0,
            loading: false,
          },
          user: ["mitra", "driver"],
          label: {
            en: "Domicile Letter (Optional)",
            id: "Surat Domisili (Optional)",
          },
          file: null,
          noted:
            "Note: Jika Anda belum bisa mengunggah Surat Domisili, Anda bisa melampirkan Surat Domisili pada saat sesi tanda tangan kontrak.",
          isUploaded: false,
          key: "file_domisili",
        },
      },
    };
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  async mounted() {},
  async created() {
    this.$store.commit("setDataStepDone", false);
    await this.initUser();
  },
  methods: {
    async getData() {
      const token = this.$cookies.get("token_api");

      await getPrivateDocApi(token).then((ress) => {
        const fileKtp = ress.data.user_documents.find(
          (n) => n.document_type == "KTP"
        )?.file?.url;
        if (fileKtp) {
          const imgKtpName = fileKtp.split("/");
          this.file.identityCard.onUpload.value = 100;
          this.file.identityCard.file = new File(
            [fileKtp],
            imgKtpName[imgKtpName.length - 1]
          );

          this.file.identityCard.isUploaded = true;
        }

        const fileKK = ress.data.user_documents.find(
          (n) => n.document_type == "KK"
        )?.file?.url;
        if (fileKK) {
          const imgKKName = fileKK.split("/");
          this.file.familyRegistration.onUpload.value = 100;
          this.file.familyRegistration.file = new File(
            [fileKK],
            imgKKName[imgKKName.length - 1]
          );

          this.file.familyRegistration.isUploaded = true;
        }

        const fileNpwp = ress.data.user_documents.find(
          (n) => n.document_type == "NPWP"
        )?.file?.url;
        if (fileNpwp) {
          const imgNpwpName = fileNpwp.split("/");
          this.file.taxpayerIdentificationNumber.onUpload.value = 100;
          this.file.taxpayerIdentificationNumber.file = new File(
            [fileNpwp],
            imgNpwpName[imgNpwpName.length - 1]
          );

          this.file.taxpayerIdentificationNumber.isUploaded = true;
        }

        const fileSimC = ress.data.user_documents.find(
          (n) => n.document_type == "SIM C"
        )?.file?.url;
        if (fileSimC) {
          const imgSimCName = fileSimC.split("/");
          this.file.drivingLicense.onUpload.value = 100;
          this.file.drivingLicense.file = new File(
            [fileSimC],
            imgSimCName[imgSimCName.length - 1]
          );

          this.file.drivingLicense.isUploaded = true;
        }

        const fileDashboardPage = ress.data.user_documents.find(
          (n) => n.document_type == "Capture Dashboard Driver"
        )?.file?.url;
        if (fileDashboardPage) {
          const imgDashboardPageName = fileDashboardPage.split("/");
          this.file.dashboardDriver.onUpload.value = 100;
          this.file.dashboardDriver.file = new File(
            [fileDashboardPage],
            imgDashboardPageName[imgDashboardPageName.length - 1]
          );

          this.file.dashboardDriver.isUploaded = true;
        }

        const fileDashboardIncome = ress.data.user_documents.find(
          (n) => n.document_type == "Capture Dashboard Income Gojek"
        )?.file?.url;
        if (fileDashboardIncome) {
          const imgDashboardIncomeName = fileDashboardIncome.split("/");
          this.file.dashboardDriverIncomeOneMonth.onUpload.value = 100;
          this.file.dashboardDriverIncomeOneMonth.file = new File(
            [fileDashboardIncome],
            imgDashboardIncomeName[imgDashboardIncomeName.length - 1]
          );

          this.file.dashboardDriverIncomeOneMonth.isUploaded = true;
        }

        const fileDomicili = ress.data.user_documents.find(
          (n) => n.document_type == "Proof of Address"
        )?.file?.url;
        if (fileDomicili) {
          const imgDomiciliName = fileDomicili.split("/");
          this.file.domisili.onUpload.value = 100;
          this.file.domisili.file = new File(
            [fileDomicili],
            imgDomiciliName[imgDomiciliName.length - 1]
          );

          this.file.domisili.isUploaded = true;
        }
      });
    },
    async initUser() {
      this.selectedUser = await this.getUser.customerType;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    fileInputClick(inputElementId) {
      const element = document.getElementById(inputElementId);
      return element.click();
    },
    fileDelete(document) {
      this.file[document].file = null;
      this.file[document].onUpload.value = 0;
      this.file[document].isUploaded = false;
      this.validationStepFourCompleted();
    },
    async onFilePicked(index) {
      let token = this.$cookies.get("token_api");
      let valid = this.$refs.refFilePicked
        .find((e) => e.id == index)
        .validate();

      const file = this.file[index]?.file;
      const progress = this.file[index].onUpload.loading;
      const isUploaded = this.file[index].isUploaded;
      if (file && valid && !progress && !isUploaded) {
        this.file[index].onUpload.loading = true;
        this.file[index].onUpload.value = 0;

        let timeLoadFile = setInterval(() => {
          let intervalValue = (this.file[index].onUpload.value += 30);

          this.file[index].onUpload.value >= 90 ||
          !this.file[index].onUpload.loading
            ? clearInterval(timeLoadFile)
            : intervalValue;
        }, 500);

        await postPrivateDocApi(token, {
          [this.file[index].key]: this.file[index].file,
          subscription_id: this.$store.getters.getIdSubs,
        }).then((res) => {
          if (res.meta.code == 200) {
            this.file[index].onUpload.loading = false;
            this.file[index].isUploaded = true;
            this.file[index].onUpload.value = 100;
          }
        });

        // const interval = setInterval(() => {
        //   if (this.file[index].onUpload.value < 100) {
        //     this.file[index].onUpload.value += 5;
        //   } else {
        //     clearInterval(interval);
        //     this.file[index].onUpload.loading = false;
        //   }
        // }, 10);
      }
    },
    validationStepFourCompleted() {
      if (
        !this.file.identityCard.isUploaded ||
        !this.file.familyRegistration.isUploaded ||
        !this.file.drivingLicense.isUploaded ||
        !this.file.dashboardDriver.isUploaded ||
        !this.file.dashboardDriverIncomeOneMonth.isUploaded
      ) {
        this.validationStepFour = false;
      } else {
        this.validationStepFour = true;
      }
    },
    async submit() {
      // let token = this.$cookies.get("token_api");
      // this.loadingBtnStep = true;
      // await postPrivateDocApi(token, {
      //   file_ktp: this.file.identityCard.file,
      //   file_kk: this.file.familyRegistration.file,
      //   file_npwp: this.file.taxpayerIdentificationNumber.file,
      //   file_sim_c: this.file.drivingLicense.file,
      //   file_payslip: this.file.payslip.file,
      //   file_capture_dashboard_driver: this.file.dashboardDriver.file,
      //   file_capture_dashboard_income_driver:
      //     this.file.dashboardDriverIncomeOneMonth.file,
      //   file_account_transaction: this.file.accountTransaction.file,
      //   file_domisili: this.file.domisili.file,
      //   subscription_id: this.$store.getters.getIdSubs,
      // }).then((res) => {
      //   if (res.meta.code == 200) {
      //     this.loadingBtnStep = false;
      //     this.$store.commit("setDataStepDone", true);
      //   } else {
      //     this.$store.commit("setDataStepDone", false);
      //   }
      // });

      this.$store.commit("setDataStepDone", true);
    },
    isAccEmpty() {
      return false;
    },
    isValid() {
      return (
        // this.file.identityCard.file == null ||
        // this.file.familyRegistration.file == null ||
        // this.file.drivingLicense.file == null ||
        // (this.file.dashboardDriver.file == null &&
        //   this.CUSTOMER_TYPE.driver.includes(this.selectedUser)) ||
        // (this.file.dashboardDriverIncomeOneMonth.file == null &&
        //   this.CUSTOMER_TYPE.driver.includes(this.selectedUser)) ||
        // this.file.identityCard.rulesFile.find(
        //   (rule) => rule(this.file.identityCard.file) !== true
        // ) ||
        // this.file.familyRegistration.rulesFile.find(
        //   (rule) => rule(this.file.familyRegistration.file) !== true
        // ) ||
        // this.file.drivingLicense.rulesFile.find(
        //   (rule) => rule(this.file.drivingLicense.file) !== true
        // ) ||
        // (this.file.dashboardDriver.rulesFile.find(
        //   (rule) => rule(this.file.dashboardDriver.file) !== true
        // ) &&
        //   this.CUSTOMER_TYPE.driver.includes(this.selectedUser)) ||
        // (this.file.dashboardDriverIncomeOneMonth.rulesFile.find(
        //   (rule) => rule(this.file.dashboardDriverIncomeOneMonth.file) !== true
        // ) &&
        //   this.CUSTOMER_TYPE.driver.includes(this.selectedUser))

        !this.file.identityCard.isUploaded ||
        !this.file.familyRegistration.isUploaded ||
        !this.file.drivingLicense.isUploaded ||
        (!this.file.dashboardDriver.isUploaded &&
          this.CUSTOMER_TYPE.driver.includes(this.selectedUser)) ||
        (!this.file.dashboardDriverIncomeOneMonth.isUploaded &&
          this.CUSTOMER_TYPE.driver.includes(this.selectedUser)) ||
        (!this.file.accountTransaction.isUploaded &&
          this.CUSTOMER_TYPE.regular.includes(this.selectedUser))
      );
    },
  },
};
</script>

<style></style>